import React, { useEffect, useState } from "react";
import AdminHeading from "../Heading/AdminHeading";
import AdminBreadCrump from "../Heading/AdminBreadCrump";
import "../../PagesStyles/AdminMasters.css";
import { TbCircleNumber1 } from "react-icons/tb";
import { a18, a35, a58, a59, a60, a7 } from "../../../Api/RootApiPath";
import { useSelector } from "react-redux";
import { RiListUnordered, RiPlayListAddLine } from "react-icons/ri";

export default function AdminAddEmployee() {
  const [active, setActive] = useState("List");
  const [allCategories, setAllCategories] = useState([]);
  const [newCategory, setNewCategory] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
  });
  const allStates = useSelector((state) => state);
  const adminLoggedIn = allStates.reducer1;
  let Entryby_Staff_id = parseInt(adminLoggedIn);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const fetchAllCategory = async () => {
    try {
      const response = await fetch(a59);
      const data = await response.json();
      setAllCategories(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllCategory();
  }, []);
  console.log(allCategories);
  const [editingId, setEditingId] = useState(null);
  const [editedData, setEditedData] = useState({});

  const handleEditClick = (id) => {
    setEditingId(id);
    // Find the item with the selected ID and set its data in the state
    const selectedItem = allCategories.find((x) => x.id === id);
    setEditedData(selectedItem);
  };

  const handleSaveClick = () => {
    handleSubmit();
    // Save the edited data to your state or send it to an API
    console.log("Edited Data:", editedData);
    setEditingId(null); // Exit editing mode
  };
  const handleSubmit = async () => {
    try {
      const response = await fetch(a60, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editedData),
      });
      const data = await response.json();

      console.log(data, "updated");
      fetchAllCategory();
    } catch (error) {
      console.error(error);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Update the edited data in the state
    setEditedData({ ...editedData, [name]: value });
  };

  const handleNewCategoryChange = (e) => {
    const { name, value } = e.target;
    // Update the edited data in the state
    setNewCategory({ ...newCategory, [name]: value });
  };
  const addNewCategory = async (e) => {
    e.preventDefault();
    const formData = {
      firstname: newCategory.firstname,
      lastname: newCategory.lastname,
      email: newCategory.email,
      mobile: newCategory.mobile,
    };
    try {
      const response = await fetch(a58, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      fetchAllCategory();
      setActive("List");
      setNewCategory({
        firstname: "",
        lastname: "",
        email: "",
        mobile: "",
      });
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <AdminHeading />
      <div className="adminMainBodyBox">
        <AdminBreadCrump
          title={"Add Employee"}
          companyName={"Loyalstring"}
          module={"Masters"}
          page={"Employee"}
        />
        <div className="adminAddCategoryMainBox">
          <div className="adminAddCategoryInnerBox">
            <div className="adminAddCategoryInnerBoxTitlesBox">
              <div
                onClick={() => {
                  setActive("List");
                }}
                className={
                  active === "List"
                    ? "adminAddCategoryInnerBoxTitle"
                    : "adminAddCategoryInnerBoxTitle activeCategoryTitle"
                }
              >
                <div
                  className={
                    active === "List"
                      ? "adminAddCategoryInnerBoxTitleLogo"
                      : "adminAddCategoryInnerBoxTitleLogo activeCategoryLogo"
                  }
                >
                  {/* 01 */}
                  <RiListUnordered />
                </div>
                <p>All Employees</p>
              </div>

              <div
                onClick={() => setActive("AddNew")}
                className={
                  active === "AddNew"
                    ? "adminAddCategoryInnerBoxTitle"
                    : "adminAddCategoryInnerBoxTitle activeCategoryTitle"
                }
              >
                <div
                  className={
                    active === "AddNew"
                      ? "adminAddCategoryInnerBoxTitleLogo"
                      : "adminAddCategoryInnerBoxTitleLogo activeCategoryLogo"
                  }
                >
                  {/* 02 */}
                  <RiPlayListAddLine />
                </div>
                <p>Add Employee</p>
              </div>
            </div>
            <div
              className={
                active === "List" ? "adminCategoryListMainBox" : "none"
              }
            >
              <table>
                <thead>
                  <tr>
                    <th>Edit</th>
                    <th>ID</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                  </tr>
                </thead>
                <tbody>
                  {allCategories.map((x) => (
                    <tr key={x.id}>
                      <td>
                        {editingId === x.id ? (
                          <button
                            className="adminAddCategorySaveButton"
                            onClick={handleSaveClick}
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            className="adminAddCategoryEditButton"
                            onClick={() => handleEditClick(x.id)}
                          >
                            Edit
                          </button>
                        )}
                      </td>
                      <td>{x.id}</td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="firstname"
                            value={editedData.firstname || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.firstname
                        )}
                      </td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="lastname"
                            value={editedData.lastname || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.lastname
                        )}
                      </td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="email"
                            value={editedData.email || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.email
                        )}
                      </td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="mobile"
                            value={editedData.mobile || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.mobile
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div
              className={
                active !== "List" ? "adminCategoryAddCategoryMainBox" : "none"
              }
            >
              <p>Add New Employee</p>
              <form onSubmit={addNewCategory}>
                <div className="adminCategoryAddCategoryInnerBox">
                  <label>First Name</label>
                  <input
                    name="firstname"
                    value={newCategory.firstname}
                    onChange={handleNewCategoryChange}
                    type="text"
                    required="required"
                  />
                  <label>Last Name</label>
                  <input
                    name="lastname"
                    value={newCategory.lastname}
                    onChange={handleNewCategoryChange}
                    type="text"
                  />
                  <label>Email</label>
                  <input
                    name="email"
                    value={newCategory.email}
                    onChange={handleNewCategoryChange}
                    type="text"
                  />
                  <label>Mobile</label>
                  <input
                    name="mobile"
                    value={newCategory.mobile}
                    onChange={handleNewCategoryChange}
                    type="text"
                  />

                  <button type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
