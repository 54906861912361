import React, { useEffect, useState } from "react";
import AdminHeading from "../Heading/AdminHeading";
import AdminBreadCrump from "../Heading/AdminBreadCrump";
import "../../PagesStyles/AdminMasters.css";
import { TbCircleNumber1 } from "react-icons/tb";
import {
  a1,
  a18,
  a20,
  a21,
  a22,
  a23,
  a27,
  a28,
  a36,
  a37,
  a4,
  a40,
} from "../../../Api/RootApiPath";
import { useSelector } from "react-redux";
import { allStateList } from "../../../Api/StateList";
import { RiListUnordered, RiPlayListAddLine } from "react-icons/ri";

export default function AdminAllCustomers() {
  const [active, setActive] = useState("List");
  const [allCustomersData, setAllCustomersData] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [newCs, setNewCs] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    currAddStreet: "",
    currAddTown: "",
    currAddState: "",
    currAddPinCode: "",
    perAddStreet: "",
    perAddTown: "",
    perAddState: "",
    perAddPinCode: "",
    aadharNo: "",
    panNo: "",
    gstNo: "",
    balanceAmount: "",
    advanceAmount: "",
  });
  const allStates = useSelector((state) => state);
  const adminLoggedIn = allStates.reducer1;
  let Entryby_Staff_id = parseInt(adminLoggedIn);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const fetchAllCustomers = async () => {
    await fetch(a1)
      .then((res) => res.json())
      .then((data) => setAllCustomersData(data.data));
  };
  console.log(allCustomersData);

  useEffect(() => {
    fetchAllCustomers();
  }, []);

  const handleEditClick = (id) => {
    setEditingId(id);
    // Find the item with the selected ID and set its data in the state
    const selectedItem = allCustomersData.find((x) => x.id === id);
    setEditedData(selectedItem);
  };

  const handleSaveClick = () => {
    handleSubmit();
    // Save the edited data to your state or send it to an API
    console.log("Edited Data:", editedData);
    setEditingId(null); // Exit editing mode
  };
  const handleSubmit = async () => {
    try {
      const response = await fetch(a40, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editedData),
      });
      const data = await response.json();

      console.log(data, "updated");
      fetchAllCustomers();
    } catch (error) {
      console.error(error);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Update the edited data in the state
    setEditedData({ ...editedData, [name]: value });
  };

  const handleNewCsChange = (e) => {
    const { name, value } = e.target;
    // Update the edited data in the state
    setNewCs({ ...newCs, [name]: value });
  };
  const addnewCs = async (e) => {
    e.preventDefault();
    const formData = {
      firstName: newCs.firstName,
      lastName: newCs.lastName,
      email: newCs.email !== "" ? newCs.email : `${newCs.mobile}@example.com`,
      password: newCs.password,
      mobile: newCs.mobile,
      advanceAmount: newCs.advanceAmount,
      balanceAmount: newCs.balanceAmount,
      currAddStreet: newCs.currAddStreet,
      currAddTown: newCs.currAddTown,
      currAddState: newCs.currAddState,
      currAddPinCode: newCs.currAddPinCode,
      perAddStreet: newCs.perAddStreet,
      perAddTown: newCs.perAddTown,
      perAddState: newCs.perAddState,
      perAddPinCode: newCs.perAddPinCode,
      aadharNo: newCs.aadharNo,
      panNo: newCs.panNo,
      gstNo: newCs.gstNo,
    };
    try {
      console.log(formData);
      const response = await fetch(a4, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data.message === "email already exist") {
        alert("email already exist"); // setAlertType("Email Already Exists, Try Login or use another email");
      } else {
        alert("added Successfully");
        fetchAllCustomers();
        setActive("List");
        console.log(data, "newCsData");
        setNewCs({
          firstName: "",
          lastName: "",
          email: "",
          mobile: "",
          currAddStreet: "",
          currAddTown: "",
          currAddState: "",
          currAddPinCode: "",
          perAddStreet: "",
          perAddTown: "",
          perAddState: "",
          perAddPinCode: "",
          aadharNo: "",
          panNo: "",
          gstNo: "",
          advanceAmount: "",
          balanceAmount,
        });
      }
    } catch (error) {
      console.error(error);
      alert("fail");
      alert();
    }
  };

  return (
    <div>
      <AdminHeading />
      <div className="adminMainBodyBox">
        <AdminBreadCrump
          title={"Add Customer"}
          companyName={"Loyalstring"}
          module={"Masters"}
          page={"Customers"}
        />
        <div className="adminAddCategoryMainBox">
          <div className="adminAddCategoryInnerBox">
            <div className="adminAddCategoryInnerBoxTitlesBox">
              <div
                onClick={() => {
                  setActive("List");
                }}
                className={
                  active === "List"
                    ? "adminAddCategoryInnerBoxTitle"
                    : "adminAddCategoryInnerBoxTitle activeCategoryTitle"
                }
              >
                <div
                  className={
                    active === "List"
                      ? "adminAddCategoryInnerBoxTitleLogo"
                      : "adminAddCategoryInnerBoxTitleLogo activeCategoryLogo"
                  }
                >
                  <RiListUnordered />
                </div>
                <p>All Customers</p>
              </div>

              <div
                onClick={() => setActive("AddNew")}
                className={
                  active === "AddNew"
                    ? "adminAddCategoryInnerBoxTitle"
                    : "adminAddCategoryInnerBoxTitle activeCategoryTitle"
                }
              >
                <div
                  className={
                    active === "AddNew"
                      ? "adminAddCategoryInnerBoxTitleLogo"
                      : "adminAddCategoryInnerBoxTitleLogo activeCategoryLogo"
                  }
                >
                  <RiPlayListAddLine />
                </div>
                <p>Add Customer</p>
              </div>
            </div>
            <div
              className={
                active === "List" ? "adminCategoryListMainBox" : "none"
              }
            >
              <table>
                <thead>
                  <tr>
                    <th>Edit</th>
                    <th>ID</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Mobile</th>
                    <th>Balance Amount</th>
                    <th>Advance Amount</th>
                    <th>GSTIN No</th>
                    <th>Billing Address</th>
                    <th>Pemanent Address</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {allCustomersData.map((x) => (
                    <tr key={x.id}>
                      <td>
                        {editingId === x.id ? (
                          <button
                            className="adminAddCategorySaveButton"
                            onClick={handleSaveClick}
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            className="adminAddCategoryEditButton"
                            onClick={() => handleEditClick(x.id)}
                          >
                            Edit
                          </button>
                        )}
                      </td>
                      <td>{x.id}</td>
                      {/* <td> {x.firstName} {x.lastName}</td> */}
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="firstName"
                            value={editedData.firstName || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.firstName
                        )}
                      </td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="lastName"
                            value={editedData.lastName || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.lastName
                        )}
                      </td>{" "}
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="mobile"
                            value={editedData.mobile || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.mobile
                        )}
                      </td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="mobile"
                            value={editedData.balanceAmount || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.balanceAmount
                        )}
                      </td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="mobile"
                            value={editedData.advanceAmount || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.advanceAmount
                        )}
                      </td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="gstNo"
                            value={editedData.gstNo || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.gstNo
                        )}
                      </td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="currAddStreet"
                            value={editedData.currAddStreet || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          <p>
                            {x.currAddStreet} {x.currAddTown} {x.currAddState}{" "}
                            {x.currAddPinCode}
                          </p>
                        )}
                      </td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="perAddStreet"
                            value={editedData.perAddStreet || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          <p>
                            {x.perAddStreet} {x.perAddTown} {x.perAddState}{" "}
                            {x.perAddPinCode}
                          </p>
                        )}
                      </td>
                      <td>{x.email}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div
              className={
                active !== "List" ? "adminCategoryAddCategoryMainBox" : "none"
              }
            >
              <p>Add New Customer</p>
              <form onSubmit={addnewCs}>
                <div className="adminCategoryAddCategoryInnerBox">
                  <label>Customer Code</label>
                  {/* {allCustomersData ? ( */}
                  <input
                    name="supplier_code"
                    value={allCustomersData.length + 1}
                    readOnly
                    type="text"
                  />
                  {/* ) : ( */}
                  {/* <input name="supplier_code" value={1} readOnly type="text" /> */}
                  {/* )} */}
                  {/* <label>Supplier Type</label>
                  <select
                    name="supplierType"
                    value={newCs.supplierType || ""}
                    onChange={handleNewCsChange}
                    required
                  >
                    <option value="">Select an option</option>
                    <option value="Party">Party</option>
                    <option value="Karigar">Karigar</option>
                  </select> */}
                  <label>First Name</label>
                  <input
                    name="firstName"
                    value={newCs.firstName}
                    onChange={handleNewCsChange}
                    type="text"
                    required="required"
                  />
                  <label>Last Name</label>
                  <input
                    name="lastName"
                    value={newCs.lastName}
                    onChange={handleNewCsChange}
                    type="text"
                    required="required"
                  />
                  <label>Email</label>
                  <input
                    name="email"
                    value={newCs.email}
                    required="required"
                    onChange={handleNewCsChange}
                    type="text"
                  />
                  <label>Mobile</label>
                  <input
                    name="mobile"
                    value={newCs.mobile}
                    onChange={handleNewCsChange}
                    type="text"
                  />
                  <label>Aadhar Number</label>
                  <input
                    name="aadharNo"
                    value={newCs.aadharNo}
                    onChange={handleNewCsChange}
                    type="text"
                  />
                  <label>Pan Number</label>
                  <input
                    name="panNo"
                    value={newCs.panNo}
                    onChange={handleNewCsChange}
                    type="text"
                  />
                  <label>GST Number</label>
                  <input
                    name="gstNo"
                    value={newCs.gstNo}
                    onChange={handleNewCsChange}
                    type="text"
                  />

                  <label>Billing Address (Street)</label>
                  <input
                    name="currAddStreet"
                    value={newCs.currAddStreet}
                    onChange={handleNewCsChange}
                    type="text"
                  />
                  <label>Billing Address (Town)</label>
                  <input
                    name="currAddTown"
                    value={newCs.currAddTown}
                    onChange={handleNewCsChange}
                    type="text"
                  />
                  <label>Billing Address (State)</label>
                  <select
                    // required="required"
                    type="text"
                    name="currAddState"
                    value={newCs.currAddState}
                    onChange={handleNewCsChange}
                  >
                    <option value="">Select a state</option>
                    {allStateList.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                  <label>Billing Address (Pincode)</label>
                  <input
                    name="currAddPinCode"
                    value={newCs.currAddPinCode}
                    onChange={handleNewCsChange}
                    type="text"
                  />
                  <label>PerAdd (Street)</label>
                  <input
                    name="perAddStreet"
                    value={newCs.perAddStreet}
                    onChange={handleNewCsChange}
                    type="text"
                  />
                  <label>PerAdd (Town)</label>
                  <input
                    name="perAddTown"
                    value={newCs.perAddTown}
                    onChange={handleNewCsChange}
                    type="text"
                  />
                  <label>PerAdd (State)</label>
                  <select
                    // required="required"
                    type="text"
                    name="perAddState"
                    value={newCs.perAddState}
                    onChange={handleNewCsChange}
                  >
                    <option value="">Select a state</option>
                    {allStateList.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                  <label>PerAdd (Pincode)</label>
                  <input
                    name="perAddPinCode"
                    value={newCs.perAddPinCode}
                    onChange={handleNewCsChange}
                    type="text"
                  />

                  <button type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
