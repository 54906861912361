import React, { useEffect, useState } from "react";
import AdminBreadCrump from "../Heading/AdminBreadCrump";
import AdminHeading from "../Heading/AdminHeading";
import { a38, a39, a45, a51, a52 } from "../../../Api/RootApiPath";
import { InfinitySpin } from "react-loader-spinner";
import { BsHandbag } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import logoImage from "../../../Images/loyalStringLogoSmall.png";
import { useSelector } from "react-redux";
import { numberToIndianWords } from "../../../Other Functions/numberToIndianWords";

export default function AdminSaleReport() {
  const [allOrders, setAllOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [orderStatus, setOrderStatus] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = 25;
  const [totalSales, setTotalSales] = useState(0);
  const [totalPurchase, setTotalPurchase] = useState(0);
  const [totalRecieved, setTotalRecieved] = useState(0);

  const allStates = useSelector((state) => state);

  const navigate = useNavigate();
  useEffect(() => {
    // Fetch all orders
    fetch(a38)
      .then((res) => res.json())
      .then((response) => {
        let rcvdData = response.data.reverse();
        const ordersWithSrno = rcvdData.map((order, index) => {
          return {
            ...order,
            srno: index + 1,
          };
        });
        setAllOrders(ordersWithSrno);
        setLoading(false);
      });
  }, []);

  const filterOrders = () => {
    let filtered = allOrders;

    if (orderStatus && orderStatus !== "") {
      filtered = filtered.filter((order) => order.orderStatus === orderStatus);
    }

    if (orderNumber && orderNumber !== "") {
      const lowercaseOrderNumber = orderNumber.toLowerCase();
      filtered = filtered.filter((order) => {
        const customer = order.tblCustomerDetails;
        const invoiceNoMatch =
          order.invoiceNo &&
          order.invoiceNo.toLowerCase().includes(lowercaseOrderNumber);
        const firstNameMatch =
          customer.firstName &&
          customer.firstName.toLowerCase().includes(lowercaseOrderNumber);
        const lastNameMatch =
          customer.lastName &&
          customer.lastName.toLowerCase().includes(lowercaseOrderNumber);
        const currAddStreetMatch =
          customer.currAddStreet &&
          customer.currAddStreet.toLowerCase().includes(lowercaseOrderNumber);
        const currAddTownMatch =
          customer.currAddTown &&
          customer.currAddTown.toLowerCase().includes(lowercaseOrderNumber);
        const currAddStateMatch =
          customer.currAddState &&
          customer.currAddState.toLowerCase().includes(lowercaseOrderNumber);
        const currAddPincodeMatch =
          customer.currAddPincode &&
          customer.currAddPincode.toLowerCase().includes(lowercaseOrderNumber);
        const perAddStreetMatch =
          customer.perAddStreet &&
          customer.perAddStreet.toLowerCase().includes(lowercaseOrderNumber);
        const perAddTownMatch =
          customer.perAddTown &&
          customer.perAddTown.toLowerCase().includes(lowercaseOrderNumber);
        const perAddStateMatch =
          customer.perAddState &&
          customer.perAddState.toLowerCase().includes(lowercaseOrderNumber);
        const perAddPincodeMatch =
          customer.perAddPincode &&
          customer.perAddPincode.toLowerCase().includes(lowercaseOrderNumber);
        const csMobileMatch =
          customer.mobile &&
          customer.mobile.toLowerCase().includes(lowercaseOrderNumber);
        const rcvdAmtMatch =
          order.receivedAmt &&
          order.receivedAmt.toString().includes(lowercaseOrderNumber);
        const priceAmtMatch =
          order.price && order.price.toString().includes(lowercaseOrderNumber);

        return (
          invoiceNoMatch ||
          firstNameMatch ||
          lastNameMatch ||
          currAddStreetMatch ||
          currAddTownMatch ||
          currAddStateMatch ||
          currAddPincodeMatch ||
          perAddStreetMatch ||
          perAddTownMatch ||
          perAddStateMatch ||
          perAddPincodeMatch ||
          csMobileMatch ||
          rcvdAmtMatch ||
          priceAmtMatch
        );
      });
    }

    if (fromDate !== "" && toDate !== "") {
      filtered = filtered.filter((order) => {
        const orderDate = new Date(order.createdOn);
        return orderDate >= new Date(fromDate) && orderDate <= new Date(toDate);
      });
    }

    setFilteredOrders(filtered);
    setCurrentPage(1); // Reset to the first page after filtering
  };

  useEffect(() => {
    filterOrders();
    window.scrollTo(0, 0);

    console.log(filteredOrders);
  }, [orderStatus, orderNumber, allOrders, fromDate, toDate]);

  // useEffect(() => {}, [currentPage]);
  // console.log(JSON.stringify(orderItems), "orderItems");
  console.log(allOrders);
  const indexOfLastProduct = currentPage * ordersPerPage;
  const indexOfFirstProduct = indexOfLastProduct - ordersPerPage;
  const currentOrders = filteredOrders.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const totalPages = Math.ceil(filteredOrders.length / ordersPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };
  useEffect(() => {
    // Filter orders for today's date
    const today = new Date().toLocaleDateString();
    const todayOrders = allOrders.filter(
      (order) => new Date(order.createdOn) === today
    );

    // Calculate total sales and total purchase amounts for today
    const totalSalesAmount = currentOrders.reduce(
      (acc, order) => acc + parseFloat(order.price),
      0
    );

    const totalPurchaseAmount = currentOrders.reduce(
      (acc, order) => acc + parseFloat(order.purchaseAmt),
      0
    );
    const totalRecievesAmount = currentOrders.reduce(
      (acc, order) => acc + parseFloat(order.receivedAmt),
      0
    );

    // Set the state variables for total sales and total purchase
    setTotalSales(totalSalesAmount);
    setTotalPurchase(totalPurchaseAmount);
    setTotalRecieved(totalRecievesAmount);
  }, [orderStatus, orderNumber, fromDate, toDate, currentOrders]);
  return (
    <div>
      <AdminHeading />

      <div className="adminMainBodyBox">
        <AdminBreadCrump
          title={"All Orders"}
          companyName={"Loyalstring"}
          module={"E-commerce"}
          page={"All Orders"}
        />
        <div className="adminAddCategoryMainBox">
          <div
            style={{ marginBottom: "50px" }}
            className="adminAddCategoryInnerBox"
          >
            <div className={loading == true ? "loading" : "none"}>
              {/* <h1>Loading...</h1> */}
              <InfinitySpin width="200" color="#4fa94d" />
            </div>
            {/* <div
              style={{ marginInline: "0px", paddingInline: "0px" }}
              className="adminAllOrdersFilterBox"
            > */}
            <div
              style={{
                width: "100%",
                justifyContent: "left",
                flexWrap: "wrap",
                marginBottom: "30px",
              }}
              className="adminAllProductsFilterBox"
            >
              <div
                style={{ marginBottom: "5px" }}
                className="adminAllProductsFilterCategoryBox"
              >
                <select
                  value={orderStatus}
                  onChange={(e) => {
                    setOrderStatus(e.target.value), setCurrentPage(1);
                  }}
                >
                  <option value="">Choose...</option>
                  <option value="Pending Payment">Pending Payment</option>
                  <option value="Payment Failed">Payment Failed</option>
                  <option value="Paid">Paid</option>
                  <option value="Shipped">Shipped</option>
                  <option value="Delivered">Delivered</option>
                </select>
              </div>
              <div
                className="adminAllProductsFilterLabelBox"
                // className="adminAllOrderLeftBox"
              >
                <input
                  type="date"
                  placeholder="From Date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
                <input
                  type="date"
                  placeholder="To Date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <div
                className="adminAllProductsFilterLabelBox"
                // className="adminAllOrderLeftBox"
              >
                <input
                  type="text"
                  placeholder="Search Name / Address / Mobile / Amount / Invoice..."
                  value={orderNumber}
                  onChange={(e) => {
                    setOrderNumber(e.target.value.toLowerCase()),
                      setCurrentPage(1);
                  }}
                />
                {/* <p>Status</p> */}
              </div>
              <div
                className="adminAllProductsFilterLabelBox"
                // className="adminAllOrderLeftBox"
              >
                <input
                  type="text"
                  readOnly
                  placeholder="From Sales"
                  value={`Total Sales - ${parseInt(totalSales).toLocaleString(
                    "INR"
                  )}`}
                />
                <input
                  style={{ marginInline: "10px" }}
                  type="text"
                  placeholder="Total Purchase"
                  value={`Total Purchase - ${parseInt(
                    totalPurchase
                  ).toLocaleString("INR")}`}
                  readOnly
                />
                <input
                  style={{ marginInline: "10px" }}
                  type="text"
                  placeholder="Total Recieved"
                  value={`Total Recieved - ${parseInt(
                    totalRecieved
                  ).toLocaleString("INR")}`}
                  readOnly
                />
              </div>
            </div>
            {/* <div className="adminAllOrderRightBox">
                <button>
                  <BsHandbag style={{ marginRight: "5px" }} />
                  Add New Order
                </button>
                <button>Export</button>
              </div> */}
            {/* </div> */}
            <div
              className="adminAllOrdersTableMainBox"
              style={{ overflow: "auto" }}
            >
              <table
                className="adminInventoryMainTable"
                style={{
                  width: "100%",
                  marginLeft: "1rem",
                  boxSizing: "border-box",
                }}
              >
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Invoice No</th>
                    {/* <th>Customer Id</th> */}
                    <th>Customer Name</th>
                    <th>Price</th>
                    <th>Purchase Amt</th>
                    {/* <th>Product Id</th> */}
                    {/* <th>Product Name</th> */}
                    {/* <th>Item Code</th> */}
                    {/* <th>Quantity</th> */}
                    <th>Recieved Amt</th>
                    <th>Created On</th>
                    <th>Order Status</th>
                    {/* <th>Payment Mode</th> */}
                  </tr>
                </thead>
                <tbody>
                  {currentOrders.map((x) => {
                    return (
                      // <tr key={x.Customer_id}>
                      <tr style={{ whiteSpace: "nowrap" }} key={x.id}>
                        <td>{x.srno}</td>
                        <td className="adminAllOrdersOrderData">
                          {x.invoiceNo}
                        </td>
                        {/* <td>{x.customer_Id}</td> */}
                        {/* NOTE:"Please Uncomment bekow line" */}
                        <td>
                          {x.tblCustomerDetails.firstName +
                            " " +
                            x.tblCustomerDetails.lastName}
                        </td>
                        <td>₹{parseInt(x.price).toLocaleString("en-IN")}</td>
                        <td>
                          ₹{parseInt(x.purchaseAmt).toLocaleString("en-IN")}
                        </td>
                        {/* <td>{x.product_id}</td> */}
                        {/* <td>{x.tblProduct.product_Name}</td> */}
                        {/* <td
                          className="adminAllOrdersOrderData"
                          onClick={() => {
                            navigate(`/admin-orderdetails/${x.id}`);
                          }}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {x.tblProduct.itemCode}
                        </td> */}

                        {/* <td>{x.qty}</td> */}
                        <td>
                          ₹{parseInt(x.receivedAmt).toLocaleString("en-IN")}
                        </td>
                        <td>{new Date(x.createdOn).toLocaleDateString()}</td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          <p
                            style={
                              x.orderStatus === "Paid"
                                ? {
                                    backgroundColor: "rgba(0, 128, 0, 0.7)",
                                    color: "white",
                                    borderRadius: "5px",
                                    padding: "2px",
                                    margin: "0px 5px",
                                  }
                                : x.orderStatus === "Processing Payment"
                                ? {
                                    backgroundColor: "rgb(219, 153, 30)",
                                    color: "white",
                                    borderRadius: "5px",
                                    padding: "2px 10px",
                                    margin: "0px 5px",
                                  }
                                : x.orderStatus === "Payment Failed"
                                ? {
                                    backgroundColor: "rgb(231, 30, 60)",
                                    color: "white",
                                    borderRadius: "4px",
                                    padding: "2px 10px",
                                    margin: "0px 5px",
                                  }
                                : x.orderStatus === "Shipped"
                                ? {
                                    backgroundColor: "rgb(77, 155, 228)",
                                    color: "white",
                                    borderRadius: "4px",
                                    padding: "2px 10px",
                                    margin: "0px 5px",
                                  }
                                : x.orderStatus === "Delivered"
                                ? {
                                    backgroundColor: "rgb(159, 77, 206)",
                                    color: "white",
                                    borderRadius: "4px",
                                    padding: "2px 10px",
                                    margin: "0px 5px",
                                  }
                                : {
                                    backgroundColor: "rgb(180, 180, 46)",
                                    color: "white",
                                    borderRadius: "4px",
                                    padding: "2px 10px",
                                  }
                            }
                          >
                            {x.orderStatus}
                          </p>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="bulkProductAddingTableMain">
                <button onClick={goToPreviousPage} disabled={currentPage === 1}>
                  Previous
                </button>
                <button
                  onClick={goToNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
