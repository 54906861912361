import React, { useEffect, useState } from "react";
import AdminHeading from "../Heading/AdminHeading";
import AdminBreadCrump from "../Heading/AdminBreadCrump";
import "../../PagesStyles/AdminTrading.css";
import {
  a1,
  a18,
  a20,
  a22,
  a4,
  a40,
  a41,
  a48,
  a49,
  a51,
  a53,
  a56,
  a57,
} from "../../../Api/RootApiPath";
import { AiOutlineEdit, AiOutlinePlusSquare } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { RiDeleteBin2Line } from "react-icons/ri";
import { BsCardImage } from "react-icons/bs";
import jsPDF from "jspdf";
import logoImage from "../../../Images/soniJewellersBillTitle.jpg";
import { GiCheckMark } from "react-icons/gi";
import { AiOutlineSend } from "react-icons/ai";
import { LiaCartPlusSolid } from "react-icons/lia";
import { MdOutlineLabelOff } from "react-icons/md";
import { useLocation, useParams } from "react-router-dom";
import { NearbyError } from "@mui/icons-material";
export default function AdminInvoiceEdit() {
  const [allCsData, setAllCsData] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedCustomerEdit, setSelectedCustomerEdit] = useState(false);
  //   const [addNewCustomer, setAddNewCustomer] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [customerMobile, setCustomerMobile] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [allProducts, setAllProducts] = useState([]);
  const [labelName, setLabelName] = useState("");
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedProductPrice, setSelectedProductPrice] = useState(0);
  const [barcode, setBarcode] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [productName, setProductName] = useState("");
  const [collectionName, setCollectionName] = useState("");
  const [purityType, setPurityType] = useState("");
  const [productQty, setProductQty] = useState("");
  const [allSelectedProducts, setAllSelectedProducts] = useState([]);
  const [allOldSelectedProducts, setAllOldSelectedProducts] = useState([]);
  const [showAllFields, setShowAllFields] = useState(false);
  const [allProdctsNetAmount, setAllProdctsNetAmount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [totalPayableGstAmount, setTotalPayableGstAmount] = useState(0);
  const [totalPayableAmount, setTotalPayableAmount] = useState(0);
  const [allProdctsGstAmount, setAllProdctsGstAmount] = useState(0);
  const [productsLoading, setProductsLoading] = useState(true);
  const [updatePrices, setUpdatePrices] = useState(false);
  const [openEditBox, setOpenEditBox] = useState(false);
  const [openEditProduct, setOpenEditProduct] = useState(null);
  const [orderId, setOrderId] = useState("");
  const [orderItemsData, setOrderItemsData] = useState("");
  const [orderItems, setOrderItems] = useState([]);
  const [orderCsData, setOrderCsData] = useState([]);
  const [oldGoldAmount, setOldGoldAmount] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [paymentOptions, setPaymentOptions] = useState("Cash");
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [payments, setPayments] = useState([]);
  const [active, setActive] = useState("Sell");
  const [allCategories, setAllCategories] = useState([]);
  const [allProductTypes, setAllProductTypes] = useState([]);
  const [allPurities, setAllPurities] = useState([]);
  const [allUnlabelList, setAllUnlabelList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedPurity, setSelectedPurity] = useState("");
  const [paidAmount, setPaidAmount] = useState(0);

  const [purchaseProduct, setPurchaseProduct] = useState({
    CategoryName: "",
    productname: "",
    id: 0,
    category_id: "",
    grosswt: "0",
    netwt: "0",
    stonewt: "0",
    GoldRate: "0",
    Finepercent: "100",
    making: "0",
    NetAmt: "0",
    GSTAmount: "0",
    TotalAmt: "0",
    Quantity: "1",
    PurchaseAmount: "0",
    purityRate: "0",
    purity: "100%",
    finalPrice: "0",
    totalGstAmount: "0",
    purchase: true,
    unlabel: false,
    tblPurity: { todaysRate: "0" },
  });
  const [unlabelProduct, setUnlabelProduct] = useState({
    CategoryName: "",
    category_id: "",
    productTypeId: "",
    product_Name: "",
    collectionName: "",
    id: 0,
    selectedUnlabelId: 0,
    grosswt: "0",
    netwt: "0",
    stonewt: "0",
    GoldRate: "0",
    Finepercent: "0",
    making: "0",
    making_Fixed_Amt: "0",
    making_Percentage: "0",
    making_per_gram: "0",
    making_Fixed_Wastage: "0",
    NetAmt: "0",
    MaxQuantity: "0",
    MaxGrossWt: "0",
    MaxNetWt: "0",
    GSTAmount: "0",
    TotalAmt: "0",
    Quantity: "1",
    unlabelAmount: "0",
    purityRate: "0",
    purity: "100%",
    finalPrice: "0",
    totalGstAmount: "0",
    purchase: false,
    unlabel: true,
    tblPurity: { todaysRate: "0" },
  });
  const [purchaseProductList, setPurchaseProductList] = useState([]);
  const [unlabelProductList, setUnlabelProductList] = useState([]);
  const [newCustomerFields, setNewCustomerFields] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    currAddStreet: "",
    currAddTown: "",
    currAddState: "",
    currAddPinCode: "",
    perAddStreet: "",
    perAddTown: "",
    perAddState: "",
    perAddPinCode: "",
    aadharNo: "",
    panNo: "",
    gstNo: "",
  });
  //   console.log(allSelectedProducts, "allSelectedProduct");
  //   useEffect(() => {
  //     fetch(a1)
  //       .then((res) => res.json())
  //       .then((response) => {
  //         setAllCsData(response.data);
  //       });
  //   }, []);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  //   const params2 = new URLSearchParams(location.search);
  const csData = params.get("csData");
  const allOrderItemsData = params.get("orderItems");
  console.log(JSON.parse(csData), "csData");

  console.log(selectedCustomer, "selectedCustomer");
  console.log(allSelectedProducts, "allSelectedProduct");
  const items = [{ id: 1, name: "saajan", lastName: "More" }];
  //   console.log(JSON.stringify(items));
  //   const objectRcvd = useParams();
  //   console.log(objectRcvd, "objectRcvd");
  //   console.log(JSON.parse(objectRcvd), "objectRcvd");
  //   console.log(objectRcvd, "objectRcvd");

  const fetchAllCustomers = async () => {
    try {
      const response = await fetch(a1);
      const data = await response.json();
      setAllCsData(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllCustomers();
  }, []);
  const fetchAllProducts = async () => {
    try {
      const response = await fetch(a41);
      const data = await response.json();
      setAllProducts(data.data);
      console.log(data, "Rcvd data");
      setProductsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchAllUnlabelledList = async () => {
    try {
      const response = await fetch(a56);
      const data = await response.json();
      setAllUnlabelList(data.data);
      console.log(data, "Rcvd Unlabel data");
      setProductsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllProducts();
  }, []);
  useEffect(() => {
    fetchAllCategories();
  }, []);
  useEffect(() => {
    fetchAllProductType();
  }, []);
  useEffect(() => {
    fetchAllPurities();
  }, []);
  useEffect(() => {
    fetchAllUnlabelledList();
  }, []);

  const fetchAllCategories = async () => {
    try {
      const response = await fetch(a18);
      const data = await response.json();
      setAllCategories(data.data);
      // console.log(data, "allcategory");
    } catch (error) {
      console.log(error);
    }
  };
  const fetchAllProductType = async () => {
    try {
      const response = await fetch(a20);
      const data = await response.json();
      setAllProductTypes(data.data);
      console.log(data, "allProductTypes");
    } catch (error) {
      console.log(error);
    }
  };
  const fetchAllPurities = async () => {
    try {
      const response = await fetch(a22);
      const data = await response.json();
      setAllPurities(data.data);
      console.log(data, "allPurities");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllProducts();
  }, []);

  useEffect(() => {
    if (selectedCustomer) {
      setCustomerName(
        `${selectedCustomer.firstName} ${selectedCustomer.lastName}`
      );
      setCustomerMobile(selectedCustomer.mobile);
      setCustomerId(selectedCustomer.id);
      setCustomerEmail(selectedCustomer.email);
    } else {
      setCustomerName("");
      setCustomerMobile("");
      setCustomerEmail("");
      setCustomerId("");
    }
  }, [selectedCustomer]);

  const filteredCustomers = allCsData.filter((customer) => {
    const fullName = `${customer.firstName} ${customer.lastName}`;
    return fullName.toLowerCase().includes(customerName.toLowerCase());
  });

  const handleNameInputChange = (e) => {
    const { value } = e.target;
    setCustomerName(value); // Update the name input value

    const selected = allCsData.find((customer) => {
      const fullName = `${customer.firstName} ${customer.lastName}`;
      return fullName.toLowerCase() === value.toLowerCase();
    });

    if (selected) {
      setCustomerEmail(selected.email);
      setCustomerId(selected.id); // Update the email input value based on selected customer's email
    }
    setSelectedCustomerEdit(false);
    setSelectedCustomer(selected); // Update the selected customer based on name match
  };

  const handleMobileInputChange = (e) => {
    const { value } = e.target;
    setCustomerMobile(value); // Update the mobile input value

    const selected = allCsData.find((customer) => customer.mobile === value);

    if (selected) {
      setCustomerEmail(selected.email);
      setCustomerId(selected.id); // Update the email input value based on selected customer's email
    }

    setSelectedCustomerEdit(false);
    setSelectedCustomer(selected); // Update the selected customer based on mobile match
  };

  const handleEmailInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerEmail(value); // Update the mobile input value

    const selected = allCsData.find((customer) => customer.email == value);
    setSelectedCustomerEdit(false);
    setSelectedCustomer(selected); // Update the selected customer based on mobile match
  };

  useEffect(() => {
    if (selectedProduct) {
      setCategoryName(selectedProduct.category_Name);
      setProductName(selectedProduct.itemType);
      setCollectionName(selectedProduct.collection);
      setPurityType(selectedProduct.purity);
      setBarcode(selectedProduct.barcodeNumber);
      setLabelName(selectedProduct.itemCode);
    } else {
      setCustomerName("");
      setCustomerMobile("");
      setCustomerEmail("");
    }
  }, [selectedProduct]);

  const handleProductLabelChange = (e) => {
    const { value } = e.target;
    setLabelName(value.toUpperCase());
    setSelectedProduct([]);
    setCategoryName("");
    setProductName("");
    setCollectionName("");
    setPurityType("");
    setProductQty("");
    setBarcode("");
    setSelectedProductPrice(0);
    if (value) {
      const selected = allProducts.find(
        (product) =>
          product.itemCode === value || product.barcodeNumber === value
      );
      if (selected) {
        setSelectedProduct(selected);
        calculateFinalPrice(selected, false);
        // addProductToList(selected);
        // setSelectedProduct([]);
        // if (labelName) {
        // } else {
        //   alert("Label is missing");
        // } // Calculate the final price
      } else {
        // console.log("Not selected");
      }
    }
  };

  const handleProductBarcodeChange = (e) => {
    const { value } = e.target;
    setBarcode(value.toUpperCase());
    setLabelName("");
    setSelectedProduct([]);
    setCategoryName("");
    setProductName("");
    setCollectionName("");
    setPurityType("");
    setProductQty("");
    setSelectedProductPrice(0);
    if (value) {
      const selected = allProducts.find(
        (product) => product.barcodeNumber === value
      );
      if (selected) {
        setSelectedProduct(selected);
        calculateFinalPrice(selected); // Calculate the final price
      } else {
        // console.log("Not selected");
      }
    }
  };

  const calculateFinalPrice = (selectedProduct, adding) => {
    let netGoldRate =
      (parseFloat(selectedProduct.netWt) *
        parseFloat(selectedProduct.tblPurity.todaysRate)) /
      10;
    let makingCharges1 =
      parseFloat(selectedProduct.netWt) *
      parseFloat(selectedProduct.making_per_gram);
    let makingCharges2 =
      (parseFloat(netGoldRate) *
        parseFloat(selectedProduct.making_Percentage)) /
      100;
    let makingCharges3 = parseFloat(selectedProduct.making_Fixed_Amt);
    let makingCharges4 =
      (parseFloat(selectedProduct.tblPurity.todaysRate) *
        parseFloat(selectedProduct.making_Fixed_Wastage)) /
      10;
    let GST = 0.03;

    let grossTotalRate =
      parseFloat(netGoldRate) +
      parseFloat(makingCharges1) +
      parseFloat(makingCharges2) +
      parseFloat(makingCharges3) +
      parseFloat(makingCharges4) +
      parseFloat(selectedProduct.stoneAmount);
    let GSTAdded = parseFloat(GST) * parseFloat(grossTotalRate);
    let finalPrice = parseFloat(grossTotalRate) + parseFloat(GSTAdded);
    if (selectedProduct.mrp !== "" && selectedProduct.mrp !== 0) {
      GSTAdded = GST * parseFloat(selectedProduct.mrp);
      finalPrice = parseFloat(selectedProduct.mrp) + parseFloat(GSTAdded);
    }

    // Calculate total making charges
    let totalMakingCharges =
      parseFloat(makingCharges1) +
      parseFloat(makingCharges2) +
      parseFloat(makingCharges3) +
      parseFloat(makingCharges4);

    let updatedProduct = {};
    // Update selectedProduct with additional properties and calculated price
    if (selectedProduct.mrp !== "" && selectedProduct.mrp !== 0) {
      updatedProduct = {
        ...selectedProduct,
        purchase: false,
        unlabel: false,
        //   finalPrice: parseFloat(finalPrice).toFixed(3),
        making: 0,
        totalGstAmount: parseFloat(selectedProduct.mrp).toFixed(3) * GST,
        finalPrice:
          parseFloat(selectedProduct.mrp) -
          parseFloat(selectedProduct.mrp).toFixed(3) * GST,
        // making: totalMakingCharges,
        // totalGstAmount: GSTAdded,
      };
      setSelectedProductPrice(parseFloat(selectedProduct.mrp).toFixed(3));
    } else {
      updatedProduct = {
        ...selectedProduct,
        purchase: false,
        unlabel: false,
        finalPrice: parseFloat(grossTotalRate).toFixed(3),
        //   finalPrice: parseFloat(finalPrice).toFixed(3),
        making: totalMakingCharges,
        totalGstAmount: GSTAdded,
      };
      setSelectedProductPrice(parseFloat(finalPrice).toFixed(3));
    }
    setSelectedProduct(updatedProduct); // Update the selected product
    if (adding) {
      // alert("");
      addProductToList(updatedProduct);
    } else if ((selectedProduct.length > 0, !adding)) {
      // alert("No Product Matched");
      // console.log("no product found");
      // alert("not");
    } else {
      null;
    }
    // setDiscountAmount(0);

    // setAllSelectedProducts((prev) => [...prev, updatedProduct]);
    // Update the price input field
  };

  const addProductToList = (selectedProduct) => {
    if (!allSelectedProducts.some((x) => x.id === selectedProduct.id)) {
      setAllSelectedProducts((prevItems) => [...prevItems, selectedProduct]);
      setLabelName("");
      setSelectedProduct([]);
      setCategoryName("");
      setProductName("");
      setCollectionName("");
      setPurityType("");
      setProductQty("");
      setSelectedProductPrice(0);
      scrollToCenter("adminInvoiceSelectLabelBox");
    } else {
      // alert("Product Already added");
      setSelectedProduct([]);
    }
  };

  const removeProductFromList = (id) => {
    const updatedProductList = allSelectedProducts.filter((x) => x.id !== id);
    setAllSelectedProducts(updatedProductList);
    if (allSelectedProducts && allSelectedProducts.length == 1) {
      scrollToCenter("adminInvoiceSelectCategoryBox");
    }
    const custData = JSON.parse(csData);
    setGrandTotal(custData.price - custData.receivedAmt);
    setPaymentAmount(custData.price - custData.receivedAmt);
    setPaidAmount(custData.receivedAmt);
  };

  useEffect(() => {
    if (selectedProduct.length > 0) {
      const finalPrice = calculateFinalPrice(
        selectedProduct.netWt,
        selectedProduct.making_per_gram,
        selectedProduct.making_Percentage,
        selectedProduct.making_Fixed_Amt,
        selectedProduct.making_Fixed_Wastage,
        selectedProduct.stoneAmount,
        selectedProduct.mrp,
        selectedProduct.tblPurity.todaysRate,
        selectedProduct.id
      );

      setSelectedProductPrice(finalPrice); // Set the calculated final price here
      setTotalPrice((x) => parseFloat(x) + finalPrice);
    }
  }, [selectedProduct]);

  const calculateNetAmount = () => {
    if (allSelectedProducts.length > 0) {
      let totalNetAmount = allSelectedProducts.reduce(
        (total, product) => total + parseFloat(product.finalPrice),
        0
      );
      let totalGstAmount = allSelectedProducts.reduce(
        (total, product) => total + parseFloat(product.totalGstAmount),
        0
      );
      let totalAmountPaying = allSelectedProducts.reduce(
        (total, product) =>
          total +
          parseFloat(product.finalPrice) +
          parseFloat(product.totalGstAmount),
        0
      );
      setAllProdctsNetAmount(parseFloat(totalNetAmount).toFixed(3));
      setAllProdctsGstAmount(parseFloat(totalGstAmount).toFixed(3));
      setTotalPayableGstAmount(parseFloat(totalGstAmount).toFixed(3));
      setGrandTotal(
        Math.ceil(
          parseFloat(totalAmountPaying - paidAmount - discountAmount)
        ).toFixed(3)
      );
      setPaymentAmount(
        Math.ceil(
          parseFloat(totalAmountPaying) - paidAmount - discountAmount
        ).toFixed(3)
      );
      setTotalPayableAmount(
        parseFloat(totalAmountPaying - discountAmount).toFixed(3)
      );
      //   if((parseFloat(totalAmountPaying - paidAmount - discountAmount))<10){
      //     setGrandTotal(
      //        0
      //       ); setPaymentAmount(
      //        0
      //       );
      //       setTotalPayableAmount(totalAmountPaying-discountAmount);
      //   }else{

      //       setGrandTotal(
      //         Math.ceil(
      //           parseFloat(totalAmountPaying - paidAmount - discountAmount)
      //         ).toFixed(3)
      //       ); setPaymentAmount(
      //         Math.ceil(
      //           parseFloat(totalAmountPaying) - paidAmount - discountAmount
      //         ).toFixed(3)
      //       );
      //       setTotalPayableAmount(parseFloat(totalAmountPaying - discountAmount).toFixed(3));
      //   }
    } else {
      setAllProdctsNetAmount(0); // Reset the total to 0 when there are no selected products
      setAllProdctsGstAmount(0); // Reset the total to 0 when there are no selected products
      setTotalPayableGstAmount(0);
      setTotalPayableAmount(0);
      setGrandTotal(0);
      setDiscountAmount(0);
      setPaymentAmount(0);
      setOldGoldAmount(0);
    }
  };
  useEffect(() => {
    calculateNetAmount();
  }, [selectedProduct, allSelectedProducts]);

  const handleProductDiscount = (e) => {
    const { value } = e.target;
    setDiscountAmount(value);
    setAllProdctsNetAmount((x) => x - value);
  };
  const recalculatePrices = () => {
    // Calculate individual product prices and update their properties
    const updatedProducts = allSelectedProducts.map((product) => {
      // Recalculate the price based on updated properties (e.g., grosswt, stoneWeight, etc.)
      const recalculatedProduct = calculateFinalPrice(product);

      return recalculatedProduct;
    });

    // Calculate the total net amount and total GST amount
    // setAllSelectedProducts(updatedProducts);
    // calculateNetAmount();

    // Update the state with the recalculated product list
    // setAllSelectedProducts(updatedProducts);
  };
  const changeTotalPrice = (value) => {
    const newTotalPayableAmount = parseInt(value);
    // console.log("TotalPayAmt", totalPayableAmount);
    // console.log("NewTotalPayAmt", newTotalPayableAmount);
    const perTotalPayableAmount = newTotalPayableAmount / 103;

    setTotalPayableGstAmount((perTotalPayableAmount * 3).toFixed(3));
    setTotalPayableAmount(value);
    // setOldGoldAmount(0);
    setPayments([...payments]);
    setAllProdctsNetAmount((parseInt(value) * 100) / 103);

    let totalAmountPaying = allSelectedProducts.reduce(
      (total, product) =>
        total +
        parseFloat(product.finalPrice) +
        parseFloat(product.totalGstAmount),
      0
    );
    setDiscountAmount(parseInt(totalAmountPaying) - parseInt(value));
    setGrandTotal(value - paidAmount);
    //   setPaymentAmount(value-parseInt(totalAmountPaying));
    setPaymentAmount(value - paidAmount);
    // setDiscountAmount(
    //   (
    //     parseInt(allProdctsNetAmount) +
    //     parseInt(perTotalPayableAmount * 3) -
    //     parseInt(newTotalPayableAmount)
    //   ).toFixed(3)
    // );

    // setTotalPayableAmount(parseFloat(e.target.value));
    // setTotalPayableGstAmount(
    //   parseFloat(newTotalPayableAmount) +
    //     parseFloat(discountAmount) -
    //     parseFloat(allProdctsNetAmount)
    // );
  };
  const changeGrandTotal = (e) => {
    const { value } = parseInt(e.target);
    setOldGoldAmount(parseInt(value));
    // console.log("TotalPayAmt", totalPayableAmount);
    // console.log("NewTotalPayAmt", newTotalPayableAmount);
    setGrandTotal(
      parseInt(parseInt(totalPayableAmount) - parseInt(oldGoldAmount))
    );
    // setTotalPayableAmount(parseFloat(e.target.value));
    // setTotalPayableGstAmount(
    //   parseFloat(newTotalPayableAmount) +
    //     parseFloat(discountAmount) -
    //     parseFloat(allProdctsNetAmount)
    // );
  };
  const handleInputChange = (e, productId, property) => {
    const barcodeInput = document.getElementById("barcodeNumberInput");
    // barcodeInput.style.setProperty("color", "black");
    const { value } = e.target;
    // setBarCodeAlert(false);
    const updatedProducts = allSelectedProducts.map((product) => {
      if (product.id === productId) {
        // Parse properties to numbers or set them as 0 if the value is empty or invalid
        const grosswt = parseFloat(product.grosswt) || 0;
        const stoneWeight = parseFloat(product.stoneWeight) || 0;
        const netWt = parseFloat(product.netWt) || 0;

        // Update the specific property in the product object
        let updatedProduct = { ...product, [property]: value };

        if (property === "barcodeNumber") {
          // Convert the barcode number to uppercase before doing the comparison
          const barcodeValue = value.toUpperCase();
          updatedProduct.barcodeNumber = barcodeValue; // Set the barcodeNumber property to uppercase

          // Find a matching product in the rifdData array
          const matchingProduct = rifdData.find(
            (item) => item.barcodeNumber === barcodeValue
          );

          if (matchingProduct) {
            updatedProduct.tid = matchingProduct.tid;
          } else {
            // If no matching product found, set 'tid' to null or some default value
            updatedProduct.tid = null; // or any default value you want
            // setBarCodeAlert(true);
          }
        }

        // If 'grosswt' is changed, calculate 'netWt'
        if (property === "grosswt" && !isNaN(value)) {
          updatedProduct.netWt = (parseFloat(value) - stoneWeight).toFixed(3);
          calculateFinalPrice(updatedProduct);
        }

        // If 'stoneWeight' is changed, calculate 'netWt'
        if (property === "stoneWeight" && !isNaN(value)) {
          updatedProduct.netWt = (grosswt - parseFloat(value)).toFixed(3);
        }

        // If 'netWt' is changed, calculate 'grosswt' and 'stoneWeight'
        if (property === "netWt" && !isNaN(value)) {
          let totalMakingCharges =
            parseFloat(updatedProduct.making_per_gram) +
            parseFloat(updatedProduct.making_Percentage) +
            parseFloat(updatedProduct.making_Fixed_Amt) +
            parseFloat(updatedProduct.making_Fixed_Wastage);
          updatedProduct.grosswt = (parseFloat(value) + stoneWeight).toFixed(3);
          updatedProduct.stoneWeight = (grosswt - parseFloat(value)).toFixed(3);
        }
        if (property === "mrp" && !isNaN(value)) {
          let totalMakingCharges =
            parseFloat(updatedProduct.making_per_gram) +
            parseFloat(updatedProduct.making_Percentage) +
            parseFloat(updatedProduct.making_Fixed_Amt) +
            parseFloat(updatedProduct.making_Fixed_Wastage);
          let GST = 0.03;
          //   updatedProduct.finalPrice = parseFloat(value).toFixed(3);
          (updatedProduct.finalPrice =
            parseFloat(updatedProduct.mrp) -
            parseFloat(updatedProduct.mrp).toFixed(3) * GST),
            (updatedProduct.making = 0);
          //   updatedProduct.totalGstAmount = 0;
          updatedProduct.totalGstAmount =
            parseFloat(updatedProduct.mrp).toFixed(3) * GST;
          //   updatedProduct.making = parseFloat(totalMakingCharges).toFixed(3);
          //   console.log(totalMakingCharges);
          //   updatedProduct.totalGstAmount = parseFloat(
          //     ((parseFloat(updatedProduct.mrp) + parseFloat(totalMakingCharges)) *
          //       GST) /
          //       100
          //   );
        }
        if (
          property === "netWt" ||
          property === "grossWt" ||
          property === "stoneWt" ||
          property === "making_per_gram" ||
          property === "making_Percentage" ||
          property === "making_Fixed_Amt" ||
          property === "making_Fixed_Wastage"
        ) {
          let netGoldRate =
            (parseFloat(updatedProduct.netWt) *
              parseFloat(updatedProduct.tblPurity.todaysRate)) /
            10;
          let makingCharges1 =
            parseFloat(updatedProduct.netWt) *
            parseFloat(updatedProduct.making_per_gram);
          let makingCharges2 =
            (parseFloat(netGoldRate) *
              parseFloat(updatedProduct.making_Percentage)) /
            100;
          let makingCharges3 = parseFloat(updatedProduct.making_Fixed_Amt);
          let makingCharges4 =
            (parseFloat(updatedProduct.tblPurity.todaysRate) *
              parseFloat(updatedProduct.making_Fixed_Wastage)) /
            10;
          let GST = 0.03;

          let grossTotalRate =
            parseFloat(netGoldRate) +
            parseFloat(makingCharges1) +
            parseFloat(makingCharges2) +
            parseFloat(makingCharges3) +
            parseFloat(makingCharges4) +
            parseFloat(updatedProduct.stoneAmount);
          let GSTAdded = parseFloat(GST) * parseFloat(grossTotalRate);
          let finalPrice = parseFloat(grossTotalRate) + parseFloat(GSTAdded);

          // Calculate total making charges
          let totalMakingCharges =
            parseFloat(makingCharges1) +
            parseFloat(makingCharges2) +
            parseFloat(makingCharges3) +
            parseFloat(makingCharges4);

          // console.log(netGoldRate, "netGoldRate");
          if (updatedProduct.mrp == 0 || updatedProduct.mrp == "") {
            updatedProduct.finalPrice = parseFloat(grossTotalRate).toFixed(3);
            updatedProduct.making = totalMakingCharges;
            updatedProduct.totalGstAmount = GSTAdded;
          }
          // Update selectedProduct with additional properties and calculated price

          setSelectedProduct(updatedProduct); // Update the selected product
          setSelectedProductPrice(parseFloat(finalPrice).toFixed(3));
        }
        return updatedProduct;
      }
      return product;
    });
    setAllSelectedProducts(updatedProducts);
  };
  //   useEffect(() => {
  //     recalculatePrices();
  //   }, [allSelectedProducts]);
  //   useEffect(() => {
  //     window.scrollTo(0, 0);
  //   }, []);

  const checkPurchaseItems = () => {
    const hasInvoiceNo = allSelectedProducts.some((item) =>
      item.hasOwnProperty("invoiceNo")
    );
    if (hasInvoiceNo) {
      console.log("At least one item has the property invoiceNo");
      const productsWithInvoiceNo = allSelectedProducts.filter((item) =>
        item.hasOwnProperty("invoiceNo")
      );
      const productsWithoutInvoiceNo = allSelectedProducts.filter(
        (item) => !item.hasOwnProperty("invoiceNo")
      );
      if (purchaseProductList.length > 0) {
        console.log("No item has the property invoiceNo");
        alert("Going for Purchase");
        //   editPurchaseOrderItems();
      } else if (unlabelProductList.length > 0) {
        alert("Going for unlabel");
        editUnlabelOrderItems();
      } else {
        //   createOrder();
        alert("Going for order");
      }
    } else {
      if (purchaseProductList.length > 0) {
        console.log("No item has the property invoiceNo");
        alert("Going for Purchase");
        //   addPurchaseOrderItems();
      } else if (unlabelProductList.length > 0) {
        //   updateUnlabelOrderItems();
        alert("Going for unlabel");
      } else {
        //   createOrder();
        alert("Going for order");
      }
    }
  };
  const addPurchaseOrderItems = async () => {
    try {
      const orderItemsList = purchaseProductList.map((product) => {
        return {
          CategoryName: `${product.CategoryName}`,
          productname: `${product.productname}`,
          grosswt: `${product.grosswt}`,
          netwt: `${product.netwt}`,
          stonewt: `${product.stonewt}`,
          GoldRate: `${product.GoldRate}`,
          Finepercent: `${product.Finepercent}`,
          NetAmt: `${product.NetAmt}`,
          GSTAmount: `${product.GSTAmount}`,
          TotalAmt: `${product.TotalAmt}`,
          Quantity: `${product.Quantity}`,
          PurchaseAmount: `${product.PurchaseAmount}`,
        };
      });
      // console.log(orderItemsList, "orderItemsList");
      const response = await fetch(a53, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(orderItemsList),
      });
      // console.log(orderItemsList, "orderItemsList");

      const rcvdData = await response.json();
      const purchaseProductsData = rcvdData.data;
      // console.log(purchaseProductsData, "purchaseProductsData");
      // Set the state with order items
      if (rcvdData.status === "error") {
        alert(rcvdData.message);
      } else {
        purchaseProductList.forEach((product, index) => {
          product.id = purchaseProductsData[index].id;
        });
        if (unlabelProductList.length > 0) {
          updateUnlabelOrderItems();
        } else {
          createOrder();
          // alert("going for order");
        }
      }
    } catch (error) {
      alert(error);
      console.error(error);
    }
  };

  const updateUnlabelOrderItems = async () => {
    try {
      // Map through unlabelProductList and update the corresponding item in allUnlabelList
      const updatedUnlabelItems = unlabelProductList.map((product) => {
        // Find the corresponding item in allUnlabelList based on id
        const filteredUnlabelItem = allUnlabelList.find(
          (x) => x.id === product.id
        );

        if (filteredUnlabelItem) {
          // Subtract quantities and other properties from filteredUnlabelItem
          filteredUnlabelItem.grosswt = (
            parseFloat(filteredUnlabelItem.grosswt) -
            parseFloat(product.grosswt)
          ).toFixed(3);
          filteredUnlabelItem.stoneWeight = (
            parseFloat(filteredUnlabelItem.stoneWeight) -
            parseFloat(product.stonewt)
          ).toFixed(3);
          filteredUnlabelItem.netWt = (
            parseFloat(filteredUnlabelItem.netWt) - parseFloat(product.netwt)
          ).toFixed(3);
          filteredUnlabelItem.quantity = (
            parseInt(filteredUnlabelItem.quantity) - parseInt(product.Quantity)
          ).toString();
        }

        return filteredUnlabelItem; // Updated unlabel item
      });

      // Filter out null or undefined items (in case any item was not found in allUnlabelList)
      const validUnlabelItems = updatedUnlabelItems.filter((item) => item);
      console.log(validUnlabelItems[0], "validUnlabelItems[0]");
      // Send the updated unlabel item to the API
      const response = await fetch(a57, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(validUnlabelItems[0]), // Assuming you're updating only one item
      });

      const rcvdData = await response.json();
      console.log(rcvdData.data, "Unlabel hit");
      if (rcvdData.status === "error") {
        alert(rcvdData.message);
      } else {
        createOrder();
        // alert("Going for order")
      }
    } catch (error) {
      alert(error);
      console.error(error);
    }
  };
  const editUnlabelOrderItems = async () => {
    try {
      // Map through unlabelProductList and update the corresponding item in allUnlabelList
      const updatedUnlabelItems = unlabelProductList.map((product) => {
        // Find the corresponding item in allUnlabelList based on id
        const filteredUnlabelItem = allUnlabelList.find(
          (x) => x.id === product.id
        );

        if (filteredUnlabelItem) {
          // Subtract quantities and other properties from filteredUnlabelItem
          filteredUnlabelItem.grosswt = (
            parseFloat(filteredUnlabelItem.grosswt) -
            parseFloat(product.grosswt)
          ).toFixed(3);
          filteredUnlabelItem.stoneWeight = (
            parseFloat(filteredUnlabelItem.stoneWeight) -
            parseFloat(product.stonewt)
          ).toFixed(3);
          filteredUnlabelItem.netWt = (
            parseFloat(filteredUnlabelItem.netWt) - parseFloat(product.netwt)
          ).toFixed(3);
          filteredUnlabelItem.quantity = (
            parseInt(filteredUnlabelItem.quantity) - parseInt(product.Quantity)
          ).toString();
        }

        return filteredUnlabelItem; // Updated unlabel item
      });

      // Filter out null or undefined items (in case any item was not found in allUnlabelList)
      const validUnlabelItems = updatedUnlabelItems.filter((item) => item);
      console.log(validUnlabelItems[0], "validUnlabelItems[0]");
      // Send the updated unlabel item to the API
      const response = await fetch(a57, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(validUnlabelItems[0]), // Assuming you're updating only one item
      });

      const rcvdData = await response.json();
      console.log(rcvdData.data, "Unlabel hit");
      if (rcvdData.status === "error") {
        alert(rcvdData.message);
      } else {
        createOrder();
        // alert("Going for order")
      }
    } catch (error) {
      alert(error);
      console.error(error);
    }
  };

  const createOrder = async () => {
    try {
      const formData = {
        Customer_Id: selectedCustomer.id,
        Product_id: allSelectedProducts[0].id,
        Qty: 1,
        // Price: `${Math.ceil(
        //   parseFloat(allProdctsNetAmount) + parseFloat(allProdctsGstAmount)
        // )}`,
        Price: `${parseFloat(totalPayableAmount).toFixed(3)}`,
        PaymentMode: `${paymentsString}`,
        PurchaseAmt: `${parseFloat(oldGoldAmount).toFixed(3)}`,
        GovtTax: `${parseFloat(totalPayableGstAmount).toFixed(3)}`,
        ReceivedAmt: Math.ceil(parseFloat(totalPaidAmount)),
        OrderStatus: "Delivered",
        CategoryName: `${allSelectedProducts[0].category_Name}`,
        Offer: `${discountAmount}`,
        Rate: `${parseFloat(allProdctsNetAmount).toFixed(3)}`,
        BillType: "true",
      };
      console.log(formData, "FORMDATA FOR ORDER");
      const response = await fetch(a48, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const rcvdData = await response.json();
      const orderData = rcvdData.data;
      // setOrderId(orderData.id);
      setOrderCsData(rcvdData.data);
      console.log(rcvdData.data, "1st hit");
      // createOrderItems(rcvdData.data.id);
      if (rcvdData.status === "error") {
        // setLoading(false);
        alert(rcvdData.message); // Show error message
      } else {
        // setLoading(false);
        console.log(rcvdData.data, "orderCreated");
        createOrderItems(rcvdData.data.id, rcvdData.data);
      }
    } catch (error) {
      alert(error);
      console.error(error);
      //   setLoading(false);
    }
  };
  const createOrderItems = async (rcvdId, x) => {
    try {
      const orderItemsList = allSelectedProducts.map((product) => {
        let item = {
          billtype: "sale",
          OrderId: rcvdId,
          CategoryName: `${product.category_Name}`,
          MRP: `${product.mrp}`,
          ProductId: product.id,
          Customer_Id: parseInt(customerId),
          ProductName: product.collection,
          Quantity: "1",
          StoneWeight: product.stoneWeight,
          HSNCode: `${product.hsn_code}` || "",
          // ItemCode: product.itemCode,
          grosswt: product.grosswt,
          Product_Code: "",
          Product_No: "",
          NetWt: product.netWt,
          StoneAmount: product.stoneAmount,
          Purity: product.purity,
          makingchrg: `${product.making}`,
          Rate: `${product.tblPurity.todaysRate}`,
          HUIDCode: product.huidCode,
          Size: product.size,
          TotalWt: product.grosswt,
          WastageWt: `${product.making_Fixed_Wastage}`,
          OnlineStatus: "Billed",
          price: `${(
            parseFloat(product.finalPrice) + parseFloat(product.totalGstAmount)
          ).toFixed(3)}`,
        };
        if (!product.unlabel && !product.purchase) {
          item.ItemCode = product.itemCode;
        }
        if (product.purchase) {
          item = {
            ...item,
            billtype: "purchase",
            ProductName: product.productname,
            CategoryName: product.CategoryName,
            MRP: "",
            Quantity: `${product.Quantity}`,
            StoneWeight: product.stonewt,
            StoneAmount: "0",
            Customer_Id: parseInt(customerId),
            HSNCode: `${product.hsn_code}` || "",
            grosswt: product.grosswt,
            NetWt: product.netwt,
            // ItemCode:"",
            Purity: product.purity,
            makingchrg: `${product.making}`,
            Rate: `${product.GoldRate}`,
            HUIDCode: product.huidCode || "",
            Size: product.size || "",
            TotalWt: product.grosswt,
            WastageWt: "0",
            purProductId: product.id,
            fine_percentage: `${product.Finepercent}`,
            price: `${(
              parseFloat(product.finalPrice) +
              parseFloat(product.totalGstAmount)
            ).toFixed(3)}`,
            // Add additional properties or modify existing properties for products with purchase = true
          };
        }

        if (product.unlabel) {
          item = {
            ...item,
            billtype: "unlabelled",
            OrderId: rcvdId,
            StoneAmount: "0",
            ProductId: product.id,
            CategoryName: product.CategoryName,
            ProductName: product.collectionName,
            Customer_Id: parseInt(customerId),
            // ItemCode:"",
            MRP: "",
            Quantity: product.Quantity,
            StoneWeight: product.stonewt,
            HSNCode: `${product.hsn_code}` || "",
            grosswt: product.grosswt,
            NetWt: product.netwt,
            Purity: product.purity,
            makingchrg: `${product.making}`,
            Rate: `${product.GoldRate}`,
            HUIDCode: product.huidCode || "",
            Size: product.size || "",
            TotalWt: product.grosswt,
            WastageWt: product.making_Fixed_Wastage,
            unlProductId: product.id,
            price: `${(
              parseFloat(product.finalPrice) +
              parseFloat(product.totalGstAmount)
            ).toFixed(3)}`,
            // Add additional properties or modify existing properties for products with purchase = true
          };
        }

        return item;
      });
      console.log(orderItemsList, "orderItemsList");
      const response = await fetch(a49, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(orderItemsList),
      });

      const rcvdData = await response.json();
      const orderData = rcvdData.data;
      console.log(orderData, "rcvd orderData");

      // Set the state with order items
      setOrderItemsData(orderData);
      setOrderItems(orderData);

      if (rcvdData.status === "error") {
        alert(rcvdData.message);
      } else {
        // Generate bill PDF after setting the state
        generateBillPDF(rcvdData.data, x);
      }
    } catch (error) {
      alert(error);
      console.error(error);
    }
  };

  // console.log(orderCsData, "orderCsData");
  const generateBillPDF = (x, csData) => {
    const doc = new jsPDF({
      orientation: "portrait",
      format: "a5",
    });

    doc.setDrawColor(0, 0, 0);
    doc.setFontSize(9);
    doc.setFont("times");
    let y = 45; // Adjust starting Y position
    const columnWidth = 15; // Adjust column widths for A5
    const contentWidth = 120; // Adjust content width for A5

    if (csData) {
      doc.text(
        `Name - ${csData.tblCustomerDetails.firstName} ${csData.tblCustomerDetails.lastName}`,
        10,
        y
      );
      doc.text(`Mobile - ${csData.tblCustomerDetails.mobile}`, 10, y + 6);
      doc.text(
        `Address - ${csData.tblCustomerDetails.currAddStreet} ${csData.tblCustomerDetails.currAddTown} ${csData.tblCustomerDetails.currAddState} ${csData.tblCustomerDetails.currAddPinCode}`,
        10,
        y + 12
      );
      doc.text(`Invoice No - ${csData.invoiceNo}`, 95, y);
      doc.text(
        `Date - ${new Date(csData.createdOn).toLocaleDateString()}`,
        95,
        y + 6
      );
      doc.text(`Email - ${csData.tblCustomerDetails.email}`, 95, y + 12);
    }
    doc.line(10, y + 20, 140, y + 20);
    y = 70;
    doc.setFontSize(8);
    doc.text("Items", 10, y);
    doc.text("Pc/Pr", 45, y);
    doc.text("HSN Code", 55, y);
    doc.text("Purity", 70, y);
    doc.text("Grs.Wt", 80, y);
    doc.text("Net.Wt", 95, y);
    doc.text("Other Cost", 110, y);
    doc.text("Price", 125, y);
    doc.line(10, y + 3, 140, y + 3);
    const maxPageHeight = doc.internal.pageSize.height - 20;

    doc.setFontSize(8);
    y += 10;
    doc.setFontSize(8);

    x.forEach((item) => {
      if (y + 8 > doc.internal.pageSize.height - 10) {
        doc.addPage();
        y = 10; // Reset Y position for the new page
      }

      const productName =
        item.productName && item.productName.length > 15
          ? item.productName.substring(0, 12) + "..."
          : item.productName;

      doc.text(productName ? productName : "-", 10, y);
      doc.text(item.quantity !== "null" ? item.quantity : "-", 45, y);
      doc.text(item.hsnCode ? item.hsnCode : "-", 55, y);
      doc.text(item.purity ? item.purity : "-", 70, y);
      doc.text(item.grosswt ? item.grosswt : "-", 80, y);
      doc.text(item.netWt ? item.netWt : "-", 95, y);
      // doc.text(item.makingchrg ? item.makingchrg : "", 110, y);
      doc.text("0.00", 110, y);
      {
        item.billtype !== "purchase"
          ? doc.text(
              item.price
                ? `${((parseFloat(item.price) * 100) / 103).toFixed(2)}`
                : "",
              125,
              y
            )
          : doc.text(
              item.price ? `${parseFloat(item.price).toFixed(2)}` : "",
              125,
              y
            );
      }

      y += 8;
    });
    doc.line(10, y - 3, 140, y - 3);
    // Add total amount
    y += 10;
    const paymentModes = csData.paymentMode
      ? csData.paymentMode.split(",")
      : [];
    // Left payment mode box
    doc.text(`Payment Mode`, 10, y);
    // doc.text(`CGST 1.5%: ${parseFloat(csData.govtTax) / 2}`, 5, y + 4);
    // doc.text(`SGST 1.5%: ${parseFloat(csData.govtTax) / 2}`, 5, y + 8);
    // doc.text(`R.O./Discount: ${csData.offer}`, 5, y + 12);
    // doc.text(`Recieved Amount: ${csData.receivedAmt}`, 5, y + 16);
    // doc.text(`Total: ${csData.price}`, 5, y + 20);
    let yPaymentModes = y + 5;
    paymentModes.forEach((paymentMode) => {
      if (yPaymentModes > maxPageHeight - 10) {
        doc.addPage();
        yPaymentModes = 5; // Reset Y position for the new page
      }

      const [mode, amount] = paymentMode.split(":");
      doc.text(`${mode}`, 10, yPaymentModes);
      doc.text(`${amount}`, 10 + columnWidth, yPaymentModes);
      yPaymentModes += 5;
    });

    // Right Total Amount box
    // doc.text(`Sales Amount: ${csData.rate}`, 105, y);
    // doc.text(`CGST 1.5%: ${parseFloat(csData.govtTax) / 2}`, 105, y + 5);
    // doc.text(`SGST 1.5%: ${parseFloat(csData.govtTax) / 2}`, 105, y + 10);
    // doc.text(`R.O./Discount: ${csData.offer}`, 105, y + 15);
    // doc.text(`Recieved Amount: ${csData.receivedAmt}`, 105, y + 20);
    // doc.text(`Total: ${csData.price}`, 105, y + 25);
    doc.text(`Sales Amount:`, 95, y);
    doc.text(`${csData.rate}`, 125, y);
    doc.text(`CGST 1.5%:`, 95, y + 5);
    doc.text(`${parseFloat(csData.govtTax) / 2}`, 125, y + 5);
    doc.text(`SGST 1.5%:`, 95, y + 10); // Adjust the X position for the second column
    doc.text(`${parseFloat(csData.govtTax) / 2}`, 125, y + 10); // Adjust the X position for the second column
    doc.text(`R.O./Discount:`, 95, y + 15);
    doc.text(`${csData.offer}`, 125, y + 15);
    doc.text(`Recieved Amount:`, 95, y + 20);
    doc.text(`${csData.receivedAmt}`, 125, y + 20);
    doc.text(`Total:`, 95, y + 25); //
    doc.text(`${csData.price}`, 125, y + 25); //

    const footerY = doc.internal.pageSize.height - 20;
    doc.setFontSize(8);
    doc.text("Raja Bazar, P.O. Jatni-752050, Khordha (Odisha)", 10, footerY);
    doc.text("Phone Number: 0674-2492089 ", 10, footerY + 4);
    doc.text("Mobile / Whatsapp: +91 7978114496 ", 10, footerY + 8);

    const pdfBlob = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl, "_blank");
  };
  // useEffect(() => {
  //   if (orderItems.length > 0) {
  //     generateBillPDF(orderItems);
  //   }
  // }, [orderItems]);
  function showPDFWithId(id) {
    // Make the API POST request with the ID
    fetch(a51, {
      method: "POST",
      body: JSON.stringify({ id: id }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.blob()) // Convert response to Blob
      .then((blob) => {
        // Create a URL for the Blob object
        const pdfUrl = URL.createObjectURL(blob);

        // Open the PDF in a new window or tab
        window.open(pdfUrl, "_blank");
        // setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  const scrollToCenter = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };
  const handleCustomerInputChange = (e, property) => {
    const updatedUserDetails = {
      ...selectedCustomer,
      [property]: e.target.value,
    };

    setSelectedCustomer(updatedUserDetails);
    // fetchAllCustomers();
    // console.log(selectedCustomer);
  };
  const handleNewCustomerInputChange = (e, property) => {
    const updatedUserDetails = {
      ...newCustomerFields,
      [property]: e.target.value,
    };

    setNewCustomerFields(updatedUserDetails);
    // fetchAllCustomers();
    // console.log(newCustomerFields);
  };
  const updateCustomerDetails = async () => {
    let updatedUserDetails = [];
    try {
      const response = await fetch(a40, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedCustomer),
      });
      const data = await response.json();
      // console.log(data, "selected Cs");
      //   alert("updated cs");
      setSelectedCustomer(data.data);
      fetchAllCustomers();
      setSelectedCustomerEdit(false);
      scrollToCenter("adminInvoiceAddCustomerTitle");
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };
  //   console.log(selectedCustomer, "selectedCustomer");
  //   console.log(customerMobile, "customerMobile");
  const checkIfNewCs = () => {
    if (customerMobile.length > 7 && !selectedCustomer) {
      addNewCustomerData();
    } else {
      alert(
        "Either Customer mobile should be greater than 8 char / customer already exists "
      );
    }
  };
  const addNewCustomerData = async () => {
    // e.preventDefault();
    const formData = {
      firstName: customerName,
      lastName: "",
      email: customerEmail ? customerEmail : `${customerMobile}@example.com`,
      password: customerMobile,
      mobile: customerMobile,
      currAddStreet: "",
      currAddTown: "",
      currAddState: "",
      currAddPinCode: "",
      perAddStreet: "",
      perAddTown: "",
      perAddState: "",
      perAddPinCode: "",
    };
    try {
      const response = await fetch(a4, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data.message === "email already exist") {
        alert("email already exist"); // setAlertType("Email Already Exists, Try Login or use another email");
      } else {
        // alert("added Successfully");
        setSelectedCustomerEdit(true);
        setSelectedCustomer(data.data);
        // console.log(data, "newCsData");
      }
    } catch (error) {
      console.error(error);
      alert("fail");
      alert();
    }
  };
  const editDiscountPrices = () => {
    setDiscountAmount(0);
    let totalAmountPaying = allSelectedProducts.reduce(
      (total, product) =>
        total +
        parseFloat(product.finalPrice) +
        parseFloat(product.totalGstAmount),
      0
    );
    let amountToDeduct =
      parseFloat(totalAmountPaying) - parseFloat(totalPayableAmount);

    let totalAdjustment = 0;
    let updatedProductList = [...allSelectedProducts];
    // Check if there are products with non-zero MRP
    if (allSelectedProducts.some((x) => x.mrp !== 0)) {
      // Sort products by descending MRP
      updatedProductList.sort((a, b) => b.mrp - a.mrp);

      // Deduct amount from products in descending order of MRP
      for (let i = 0; i < updatedProductList.length; i++) {
        if (amountToDeduct > 0) {
          const deduction = Math.min(amountToDeduct, updatedProductList[i].mrp);
          updatedProductList[i].mrp -= deduction;
          updatedProductList[i].finalPrice = (
            (updatedProductList[i].mrp * 100) /
            103
          ).toFixed(2);
          updatedProductList[i].totalGstAmount = (
            (updatedProductList[i].mrp * 3) /
            103
          ).toFixed(2);
          totalAdjustment += deduction;
          amountToDeduct -= deduction;
        } else {
          break;
        }
      }
    }

    // Distribute any remaining amount among the products
    if (amountToDeduct > 0 && updatedProductList.length > 0) {
      const totalOriginalAmount = updatedProductList.reduce(
        (total, product) =>
          total +
          parseFloat(product.finalPrice) +
          parseFloat(product.totalGstAmount),
        0
      );
      //   setDiscountAmount(0);
      updatedProductList = updatedProductList.map((product) => {
        const originalAmount =
          parseFloat(product.finalPrice) + parseFloat(product.totalGstAmount);
        const adjustment =
          (originalAmount / totalOriginalAmount) * amountToDeduct;
        // console.log(
        //   typeof parseFloat(product.finalPrice),
        //   "product.finalPrice"
        // );
        // console.log(typeof product.totalGstAmount, "product.totalGstAmount");
        // console.log(
        //   typeof parseFloat(totalPayableAmount),
        //   "totalPayableAmount"
        // );
        const deductAmount = parseFloat(
          parseFloat(product.finalPrice) +
            parseFloat(product.totalGstAmount) -
            parseFloat(totalPayableAmount)
        );
        const newTotalGstAmount =
          parseFloat(product.totalGstAmount) - parseFloat(deductAmount) * 0.03;
        const newFinalPrice = (parseFloat(totalPayableAmount) * 100) / 103;
        const newMakingPerGram =
          parseFloat(product.making) - parseFloat(deductAmount);
        // console.log(totalPayableAmount, "totalPayableAmount");
        // console.log(typeof deductAmount, "deductAmount");
        // console.log(newTotalGstAmount, "newTotalGstAmount");
        // console.log(newFinalPrice, "newFinalPrice");
        // console.log(newMakingPerGram, "newMakingPerGram");
        totalAdjustment += adjustment;

        return {
          ...product,
          finalPrice: newFinalPrice,
          making: newMakingPerGram,
          totalGstAmount: newTotalGstAmount,
        };
      });

      amountToDeduct -= totalAdjustment;
    }

    // Now, updatedProductList contains the updated products with discounted prices
    // console.log("Updated Product List with Discounts", updatedProductList);
    setAllSelectedProducts(updatedProductList);
  };
  useEffect(() => {
    editDiscountPrices();
  }, [updatePrices]);
  // const editItem = (id) => {
  //   setOpenEditBox(true);
  //   // document.body.classList.add("body-no-scroll");
  // };
  const editItem = (product) => {
    setOpenEditBox(true);
    // if (!allSelectedProducts.some((x) => x.id === selectedProduct.id)) {
    setOpenEditProduct(product);

    // } else {
    // alert("Product Already added");
    // }
  };
  const closeEditItem = () => {
    setOpenEditBox(false);
    // document.body.classList.add("body-no-scroll");
  };
  const handleInputChange2 = (e, property) => {
    const { value } = e.target;
    if (selectedProduct) {
      const updatedProduct = {
        ...openEditProduct,
        [property]: e.target.value,
      };
      const grosswt = parseFloat(updatedProduct.grosswt) || 0;
      const stoneWeight = parseFloat(updatedProduct.stoneWeight) || 0;
      const netWt = parseFloat(updatedProduct.netWt) || 0;
      if (property === "grosswt" && !isNaN(value)) {
        updatedProduct.netWt = (parseFloat(value) - stoneWeight).toFixed(3);
        // calculateFinalPrice(selectedProduct);
      }
      if (property === "stoneWeight" && !isNaN(value)) {
        updatedProduct.netWt = (grosswt - parseFloat(value)).toFixed(3);
      }
      if (property === "netWt" && !isNaN(value)) {
        updatedProduct.grosswt = (
          parseFloat(stoneWeight) + parseFloat(value)
        ).toFixed(3);
      }
      if (
        property === "netWt" ||
        property === "grosswt" ||
        property === "stoneWt" ||
        property === "making_per_gram" ||
        property === "making_Percentage" ||
        property === "making_Fixed_Amt" ||
        property === "making_Fixed_Wastage"
      ) {
        let netGoldRate =
          (parseFloat(updatedProduct.netWt) *
            parseFloat(updatedProduct.tblPurity.todaysRate)) /
          10;
        let makingCharges1 =
          parseFloat(updatedProduct.netWt) *
          parseFloat(updatedProduct.making_per_gram);
        let makingCharges2 =
          (parseFloat(netGoldRate) *
            parseFloat(updatedProduct.making_Percentage)) /
          100;
        let makingCharges3 = parseFloat(updatedProduct.making_Fixed_Amt);
        let makingCharges4 =
          (parseFloat(updatedProduct.tblPurity.todaysRate) *
            parseFloat(updatedProduct.making_Fixed_Wastage)) /
          10;
        let GST = 0.03;

        let grossTotalRate =
          parseFloat(netGoldRate) +
          parseFloat(makingCharges1) +
          parseFloat(makingCharges2) +
          parseFloat(makingCharges3) +
          parseFloat(makingCharges4) +
          parseFloat(updatedProduct.stoneAmount);
        let GSTAdded = parseFloat(GST) * parseFloat(grossTotalRate);
        let finalPrice = parseFloat(grossTotalRate) + parseFloat(GSTAdded);

        // Calculate total making charges
        let totalMakingCharges =
          parseFloat(makingCharges1) +
          parseFloat(makingCharges2) +
          parseFloat(makingCharges3) +
          parseFloat(makingCharges4);

        // console.log(netGoldRate, "netGoldRate");
        if (updatedProduct.mrp == 0 || updatedProduct.mrp == "") {
          updatedProduct.finalPrice = parseFloat(grossTotalRate).toFixed(3);
          updatedProduct.making = totalMakingCharges;
          updatedProduct.totalGstAmount = GSTAdded;
        } else {
          // updatedProduct = {
          //   ...updatedProduct,
          //   finalPrice: parseFloat(grossTotalRate).toFixed(3),
          //   //   finalPrice: parseFloat(finalPrice).toFixed(3),
          //   making: totalMakingCharges,
          //   totalGstAmount: GSTAdded,
          // };

          updatedProduct.finalPrice = 0;
          updatedProduct.making = 0;
          updatedProduct.totalGstAmount = 0;
          //   finalPrice: parseFloat(finalPrice).toFixed(3),
          // }
          setSelectedProductPrice(parseFloat(finalPrice).toFixed(3));
          setOpenEditProduct(updatedProduct);
        }
        // Update selectedProduct with additional properties and calculated price

        setSelectedProduct(updatedProduct); // Update the selected product
        setOpenEditProduct(updatedProduct);
        // calculateFinalPrice(updatedProduct);

        setSelectedProductPrice(parseFloat(finalPrice).toFixed(3));
      }
      // Update the specific product in allSelectedProducts array without changing its position
      const updatedProducts = allSelectedProducts.map((product) =>
        product.id === updatedProduct.id ? updatedProduct : product
      );
      // Update the state with the modified products array
      setOpenEditProduct(updatedProduct);
      setAllSelectedProducts(updatedProducts);

      // Update the openEditProduct state
    }
  };
  const addPayment = () => {
    // Check if both payment mode and amount are provided
    if (paymentOptions && paymentAmount > 0) {
      // Update the payments array with new payment mode and amount
      setPayments([
        ...payments,
        { mode: paymentOptions, amount: paymentAmount },
      ]);
      setGrandTotal(parseInt(grandTotal) - parseInt(paymentAmount));
      // Clear the input fields
      // setPaymentOptions("Cash");
      setPaymentAmount(parseInt(grandTotal) - parseInt(paymentAmount));
    }
  };

  const deletePayment = (index) => {
    // Get the amount of the payment to be deleted
    const deletedAmount = parseInt(payments[index].amount);

    // Remove the payment at the specified index
    const updatedPayments = [...payments];
    updatedPayments.splice(index, 1);

    // Calculate the new grand total by subtracting the deleted amount

    // Update the payments array and grand total state
    setPayments(updatedPayments);
    const newGrandTotal = grandTotal + deletedAmount;
    setGrandTotal(newGrandTotal);
    setPaymentAmount(newGrandTotal);
  };

  // Convert payments array to a comma-separated string whenever you need it
  const paymentsString = payments
    .map((payment) => `${payment.mode}:${payment.amount}`)
    .join(",");
  // Function to calculate total payment amount
  const calculateTotalAmount = () => {
    // Use reduce to sum all payment amounts

    const totalPaidAmount = payments.reduce(
      (total, payment) => total + parseFloat(payment.amount),
      0
    );
    // setPaidAmount(totalPaidAmount);
    return totalPaidAmount;
  };
  console.log(paymentsString, "paymentsString");
  // Render total payment amount
  const totalPaidAmount = calculateTotalAmount();

  //   useEffect(() => {
  //     setGrandTotal(parseFloat(grandTotal - paymentAmount));
  //     calculateTotalAmount();
  //   }, [selectedProduct, unlabelProduct, purchaseProduct]);
  const resetAllFields = () => {
    setSelectedCustomer(null);
    setSelectedProduct([]);
    setAllSelectedProducts([]);
    setPaymentAmount(0);
    setPayments([]);
  };

  // let selectedCategoryId = "";
  // let selectedCategoryName = "";
  // let selectedPurityName = "";
  // let selectedPurityRate = "";
  // console.log(purchaseProduct, "purchaseProduct");
  const handleInputChangePurchase = (e) => {
    const { name, value } = e.target;
    const updatedProduct = { ...purchaseProduct }; // Create a copy of the purchaseProduct object

    // Update the edited data in the updatedProduct object
    if (name === "category_id") {
      const [selectedCategoryId, selectedCategoryName] = value.split(",");
      setSelectedCategory(selectedCategoryName),
        (updatedProduct.category_id = selectedCategoryId),
        (updatedProduct.CategoryName = selectedCategoryName);
    } else if (name === "grosswt") {
      updatedProduct.netwt = parseFloat(
        parseFloat(value) - parseFloat(updatedProduct.stonewt)
      ).toFixed(3);
      updatedProduct.grosswt = value;
    } else if (name === "stonewt") {
      updatedProduct.netwt = parseFloat(
        parseFloat(updatedProduct.grosswt) - parseFloat(value)
      ).toFixed(3);
      updatedProduct.stonewt = value;
    } else if (name === "netwt") {
      updatedProduct.stonewt = parseFloat(
        parseFloat(updatedProduct.grosswt) - parseFloat(value)
      ).toFixed(3);
      updatedProduct.netwt = value;
    } else if (name === "purityRate") {
      const [selectedPurityName, selectedPurityRate] = value.split(",");
      setSelectedPurity(selectedPurityName);
      updatedProduct.purity = selectedPurityName;
      updatedProduct.GoldRate = selectedPurityRate;
      updatedProduct.purityRate = selectedPurityRate;
    } else if (name === "GoldRate") {
      updatedProduct.GoldRate = parseFloat(value);
      updatedProduct.purityRate = parseFloat(value);
    } else if (name === "productname") {
      updatedProduct.productname = value;
    } else {
      updatedProduct[name] = value;
    }

    // Set the state of the purchaseProduct object with the updatedProduct object
    setPurchaseProduct(updatedProduct);

    // Calculate purchase price based on the updatedProduct object
    calculatePurchasePrice(updatedProduct);

    // Rest of the function logic...
  };
  const handleInputChangeUnlabel = (e) => {
    const { name, value } = e.target;
    const updatedProduct = { ...unlabelProduct }; // Create a copy of the purchaseProduct object

    // Update the edited data in the updatedProduct object
    if (name === "category_id") {
      const [selectedCategoryId, selectedCategoryName] = value.split(",");
      setSelectedCategory(selectedCategoryName),
        (updatedProduct.category_id = selectedCategoryId),
        (updatedProduct.CategoryName = selectedCategoryName);
      (updatedProduct.productTypeId = ""),
        (updatedProduct.product_Name = ""),
        (updatedProduct.collectionName = ""),
        (updatedProduct.selectedUnlabelId = 0),
        (updatedProduct.grosswt = "0"),
        (updatedProduct.netwt = "0"),
        (updatedProduct.stonewt = "0"),
        (updatedProduct.GoldRate = "0"),
        (updatedProduct.Finepercent = "0"),
        (updatedProduct.making = "0"),
        (updatedProduct.NetAmt = "0"),
        (updatedProduct.GSTAmount = "0"),
        (updatedProduct.TotalAmt = "0"),
        (updatedProduct.Quantity = "1"),
        (updatedProduct.unlabelAmount = "0"),
        (updatedProduct.purityRate = "0"),
        (updatedProduct.purity = "100%"),
        (updatedProduct.finalPrice = "0"),
        (updatedProduct.totalGstAmount = "0"),
        (updatedProduct.purchase = false),
        (updatedProduct.unlabel = true),
        (updatedProduct.tblPurity.todaysRate = "0");
    } else if (name === "grosswt") {
      updatedProduct.netwt = parseFloat(
        parseFloat(value) - parseFloat(updatedProduct.stonewt)
      ).toFixed(3);
      updatedProduct.grosswt = value;
    } else if (name === "stonewt") {
      updatedProduct.netwt = parseFloat(
        parseFloat(updatedProduct.grosswt) - parseFloat(value)
      ).toFixed(3);
      updatedProduct.stonewt = value;
    } else if (name === "netwt") {
      updatedProduct.stonewt = parseFloat(
        parseFloat(updatedProduct.grosswt) - parseFloat(value)
      ).toFixed(3);
      updatedProduct.netwt = value;
    } else if (name === "purityRate") {
      const [selectedPurityName, selectedPurityRate] = value.split(",");
      setSelectedPurity(selectedPurityName);
      updatedProduct.purity = selectedPurityName;
      updatedProduct.GoldRate = selectedPurityRate;
      updatedProduct.purityRate = selectedPurityRate;
    } else if (name === "GoldRate") {
      updatedProduct.GoldRate = parseFloat(value);
      updatedProduct.purityRate = parseFloat(value);
    } else if (name === "productTypeId") {
      const [productIdNumber, productTypeName] = value.split(",");
      updatedProduct.productTypeId = parseInt(productIdNumber);
      updatedProduct.product_Name = productTypeName;
    } else if (name === "collectionName") {
      const [selectedUnlabelId, collectionNameSelected, collectionIdSelected] =
        value.split(",");
      updatedProduct.collectionName = collectionNameSelected;
      updatedProduct.collectionId = collectionIdSelected;
      updatedProduct.selectedUnlabelId = selectedUnlabelId;
      updatedProduct.id = parseInt(selectedUnlabelId);
      let availableQty = allUnlabelList.filter(
        (x) => x.id === parseInt(selectedUnlabelId)
      )[0].quantity;
      let availableGrossWt = allUnlabelList.filter(
        (x) => x.id === parseInt(selectedUnlabelId)
      )[0].grosswt;
      let availableNetWt = allUnlabelList.filter(
        (x) => x.id === parseInt(selectedUnlabelId)
      )[0].netWt;
      let availableFineWastage = allUnlabelList.filter(
        (x) => x.id === parseInt(selectedUnlabelId)
      )[0].fine_wastage;

      updatedProduct.MaxQuantity = availableQty;
      updatedProduct.grosswt = availableGrossWt;
      updatedProduct.netwt = availableNetWt;
      updatedProduct.MaxGrossWt = availableGrossWt;
      updatedProduct.MaxNetWt = availableNetWt;
      updatedProduct.Finepercent = availableFineWastage;
    } else {
      updatedProduct[name] = value;
    }

    // Set the state of the purchaseProduct object with the updatedProduct object
    setUnlabelProduct(updatedProduct);

    // Calculate purchase price based on the updatedProduct object
    calculateUnlabelPrice(updatedProduct);

    // Rest of the function logic...
  };
  // useEffect(() => {
  //   calculatePurchasePrice();
  // }, [purchaseProduct]);
  const calculatePurchasePrice = (purchaseProduct) => {
    let FineRate =
      (parseFloat(purchaseProduct.Finepercent) *
        parseFloat(purchaseProduct.purityRate)) /
      1000;
    let netRate = parseFloat(
      parseFloat(FineRate) *
        parseFloat(purchaseProduct.netwt) *
        parseFloat(purchaseProduct.Quantity)
    ).toFixed(3);
    let gstRate = parseFloat(netRate) * 0.03;
    let totalRate = parseFloat(parseFloat(netRate));

    setPurchaseProduct({
      ...purchaseProduct,
      NetAmt: netRate,
      GSTAmount: gstRate,
      TotalAmt: totalRate,
      PurchaseAmount: totalRate,
      finalPrice: `${-netRate + gstRate}`,
      totalGstAmount: `${-gstRate}`,
    });
    // setOldGoldAmount(parseFloat(totalRate).toFixed(0));
    // setGrandTotal(grandTotal - totalRate);
    // setPaymentAmount(grandTotal - totalRate);
    // Clear the input fields
    // setPaymentOptions("Cash");

    // setGrandTotal(
    //   parseInt(grandTotal) - parseInt(purchaseProduct.PurchaseAmount)
    // );
  };

  const calculateUnlabelPrice = (unlabelProduct) => {
    let FineRate = (100 * parseFloat(unlabelProduct.purityRate)) / 1000;
    let netRate = parseFloat(
      parseFloat(FineRate) * parseFloat(unlabelProduct.netwt)
    ).toFixed(3);
    let totalRate = parseFloat(parseFloat(netRate));

    let netGoldRate =
      (parseFloat(unlabelProduct.netwt) * parseFloat(unlabelProduct.GoldRate)) /
      10;
    let makingCharges1 =
      parseFloat(unlabelProduct.netwt) *
      parseFloat(unlabelProduct.making_per_gram);
    let makingCharges2 =
      (parseFloat(netGoldRate) * parseFloat(unlabelProduct.making_Percentage)) /
      100;
    let makingCharges3 = parseFloat(unlabelProduct.making_Fixed_Amt);
    let makingCharges4 =
      (parseFloat(unlabelProduct.GoldRate) *
        parseFloat(unlabelProduct.making_Fixed_Wastage)) /
      10;

    let totalMaking =
      parseFloat(makingCharges1) +
      parseFloat(makingCharges2) +
      parseFloat(makingCharges3) +
      parseFloat(makingCharges4);
    let gstRate = (parseFloat(netRate) + parseFloat(totalMaking)) * 0.03;
    setUnlabelProduct({
      ...unlabelProduct,
      NetAmt: parseFloat(netRate) + parseFloat(totalMaking),
      making: parseFloat(totalMaking),
      GSTAmount: gstRate,
      TotalAmt:
        parseFloat(netRate) + parseFloat(totalMaking) + parseFloat(gstRate),
      unlabelAmount: parseFloat(
        parseFloat(netRate) + parseFloat(gstRate) + parseFloat(totalMaking)
      ).toFixed(3),
      finalPrice: `${parseFloat(netRate) + parseFloat(totalMaking)}`,
      totalGstAmount: `${gstRate}`,
    });

    // setOldGoldAmount(parseFloat(totalRate).toFixed(0));
    // setGrandTotal(grandTotal - totalRate);
    // setPaymentAmount(grandTotal - totalRate);
    // Clear the input fields
    // setPaymentOptions("Cash");

    // setGrandTotal(
    //   parseInt(grandTotal) - parseInt(purchaseProduct.PurchaseAmount)
    // );
  };
  // console.log(allProductTypes);
  // console.log(purchaseProduct);

  const addPurchaseProductToList = (selectedProduct) => {
    // if (!allSelectedProducts.some((x) => x.id === selectedProduct.id)) {
    setAllSelectedProducts((prevItems) => [...prevItems, selectedProduct]);
    setLabelName("");
    setSelectedProduct([]);
    setCategoryName("");
    setProductName("");
    setCollectionName("");
    setPurityType("");
    setProductQty("");
    setSelectedProductPrice(0);
    scrollToCenter("adminInvoiceSelectLabelBox");
    setPurchaseProduct({
      CategoryName: "",
      productname: "",
      category_id: "",
      grosswt: "0",
      netwt: "0",
      stonewt: "0",
      GoldRate: "0",
      Finepercent: "0",
      making: "0",
      NetAmt: "0",
      GSTAmount: "0",
      TotalAmt: "0",
      Quantity: "1",
      PurchaseAmount: "0",
      purityRate: "0",
      purity: "100%",
      finalPrice: "0",
      totalGstAmount: "0",
      purchase: true,
      tblPurity: { todaysRate: "0" },
    });
    setUnlabelProduct({
      CategoryName: "",
      category_id: "",
      productTypeId: "",
      product_Name: "",
      collectionName: "",
      selectedUnlabelId: 0,
      grosswt: "0",
      netwt: "0",
      stonewt: "0",
      GoldRate: "0",
      Finepercent: "0",
      making_Fixed_Amt: "0",
      making_Percentage: "0",
      making_per_gram: "0",
      making_Fixed_Wastage: "0",
      making: "0",
      NetAmt: "0",
      MaxQuantity: "0",
      MaxGrossWt: "0",
      MaxNetWt: "0",
      GSTAmount: "0",
      TotalAmt: "0",
      Quantity: "1",
      unlabelAmount: "0",
      purityRate: "0",
      purity: "100%",
      finalPrice: "0",
      totalGstAmount: "0",
      purchase: false,
      unlabel: true,
      tblPurity: { todaysRate: "0" },
    });
    setActive("Sell");

    // } else {
    // alert("Product Already added");
    // setSelectedProduct([]);
    // }
  };

  useEffect(() => {
    const totalPurchaseAmount = allSelectedProducts
      .filter((x) => x.purchase === true)
      .reduce(
        (total, product) => total + parseFloat(product.PurchaseAmount),
        0
      );
    setOldGoldAmount(totalPurchaseAmount);
    setPurchaseProductList(
      allSelectedProducts.filter((x) => x.purchase === true)
    );
    setUnlabelProductList(
      allSelectedProducts.filter((x) => x.unlabel === true)
    );
    // console.log(purchaseProductList, "onlyPurchaseProducts");
  }, [allSelectedProducts]);
  const removePurchaseProductFromList = (index) => {
    const updatedProductList = allSelectedProducts.filter(
      (_, i) => i !== index
    );
    setAllSelectedProducts(updatedProductList);
    const custData = JSON.parse(csData);
    setGrandTotal(custData.price - custData.receivedAmt);
    setPaymentAmount(custData.price - custData.receivedAmt);
    setPaidAmount(custData.receivedAmt);
  };

  // console.log(purchaseProductList, "purchaseProductList");
  // console.log(selectedCustomer, "selectedCustomer");
  // console.log(selectedProduct);
  // console.log(openEditProduct, "openEditProduct");
  // console.log(paymentsString, "paymentsString");
  const filteredProducts = allProductTypes.filter(
    (product) => product.category_id == purchaseProduct.category_id
  );
  const filteredPurities = allPurities.filter((product) => {
    if (purchaseProduct.CategoryName === "Old Gold") {
      return product.category == "Gold";
    } else {
      return product.category == purchaseProduct.CategoryName;
    }
  });

  const filteredProductsUnlabel = allProductTypes.filter(
    (product) => product.category_id == unlabelProduct.category_id
  );
  const filteredPuritiesUnlabel = allPurities.filter((product) => {
    if (unlabelProduct.CategoryName === "Old Gold") {
      return product.category == "Gold";
    } else {
      return product.category == unlabelProduct.CategoryName;
    }
  });
  const filteredUnlabelCollection = allUnlabelList.filter(
    (product) => product.productTypeId == unlabelProduct.productTypeId
  );

  useEffect(() => {
    const orderItemsDetailsData = JSON.parse(allOrderItemsData);
    if (orderItemsDetailsData.length > 0) {
      const modifiedProducts = orderItemsDetailsData.map((product) => {
        if (product) {
          // Add tblPurity property for products with billtype as sale
          product.tblPurity = {
            purity: product.purity, // You can set other properties from the product object as needed
            category: product.category_Name, // Example property
            todaysRate: product.rate,
          };
        }
        if (product.billtype == "sale") {
          product.purchase = false;
          product.unlabel = false;
          product.category_Name = product.categoryName;
          product.itemType = "";
          product.collection = product.productName;
          product.finalPrice = parseFloat(
            (parseFloat(product.price) * 100) / 103
          ).toFixed(3);
          product.making = parseFloat(product.makingchrg).toFixed(0);
          product.totalGstAmount = parseFloat(
            (parseFloat(product.price) * 3) / 103
          ).toFixed(3);
        } else if (product.billtype == "purchase") {
          let FineRate =
            (parseFloat(product.fine_percentage) * parseFloat(product.rate)) /
            1000;
          let netRate = parseFloat(
            parseFloat(FineRate) *
              parseFloat(product.netWt) *
              parseFloat(product.quantity)
          ).toFixed(3);
          let gstRate = parseFloat(netRate) * 0.03;
          let totalRate = parseFloat(parseFloat(netRate));
          product.purchase = true;
          product.unlabel = false;
          product.CategoryName = product.categoryName;
          product.productname = product.productName;
          product.netwt = product.netWt;
          product.stonewt = product.stoneWeight;
          product.GoldRate = product.rate;
          product.Finepercent = product.fine_percentage;
          product.purityRate = product.rate;
          product.Quantity = product.quantity;
          product.NetAmt = netRate;
          product.GSTAmount = gstRate;
          product.TotalAmt = totalRate;
          product.PurchaseAmount = totalRate;
          product.finalPrice = `${-netRate + gstRate}`;
          product.totalGstAmount = `${-gstRate}`;
          // product.finalPrice= parseFloat(parseFloat(product.price)*100/103).toFixed(3)
          product.making = parseFloat(product.makingchrg).toFixed(0);
          // product.totalGstAmount= parseFloat(parseFloat(product.price)*3/103).toFixed(3)
        } else if (product.billtype == "unlabelled") {
          let totalMaking = parseFloat(product.makingchrg);
          let FineRate = (100 * parseFloat(product.rate)) / 1000;
          let netRate = parseFloat(
            parseFloat(FineRate) * parseFloat(product.netWt)
          ).toFixed(3);
          let totalRate = parseFloat(parseFloat(netRate));
          let gstRate = (parseFloat(netRate) + parseFloat(totalMaking)) * 0.03;
          let netGoldRate =
            (parseFloat(product.netWt) * parseFloat(product.rate)) / 10;
          product.purchase = false;
          product.unlabel = true;
          product.CategoryName = product.categoryName;
          product.product_Name = "";
          product.collectionName = product.productName;
          product.GoldRate = product.rate;
          product.netwt = product.netWt;
          product.stonewt = product.stoneWeight;
          product.NetAmt = parseFloat(netRate) + parseFloat(totalMaking);
          product.making = parseFloat(totalMaking);
          product.GSTAmount = gstRate;
          product.TotalAmt =
            parseFloat(netRate) + parseFloat(totalMaking) + parseFloat(gstRate);
          product.unlabelAmount = parseFloat(
            parseFloat(netRate) + parseFloat(gstRate) + parseFloat(totalMaking)
          ).toFixed(3);
          product.finalPrice = `${
            parseFloat(netRate) + parseFloat(totalMaking)
          }`;
          product.totalGstAmount = `${gstRate}`;
        }
        return product;
      });

      // console.log(modifiedProducts, "modifiedProducts");
      setAllSelectedProducts(modifiedProducts);
      setAllOldSelectedProducts(modifiedProducts);
      setUpdateOldPrices(!updateOldPrices);
    }
  }, []);
  const [updateOldPrices, setUpdateOldPrices] = useState(false);
  useEffect(() => {
    const custData = JSON.parse(csData);
    // console.log(custData, "custData");

    // setDiscountAmount(custData.offer);
    setTotalPayableAmount(custData.price);

    setSelectedCustomer(custData.tblCustomerDetails);

    // changeTotalPrice(`${csData.offer}`);
  }, []);
  useEffect(() => {
    const custData = JSON.parse(csData);
    // console.log(custData, "custData");

    // setDiscountAmount(custData.offer);
    setTotalPayableAmount(custData.price);

    setSelectedCustomer(custData.tblCustomerDetails);
    // changeTotalPrice(`${csData.offer}`);
  }, []);
  useEffect(() => {
    const custData = JSON.parse(csData);
    calculateNetAmount();

    if (custData && allSelectedProducts.length > 0) {
      console.log(custData.price, "2");

      const handleInitialChange = () => {
        const specificNumber = custData.price;
        changeTotalPrice(specificNumber);
      };

      // Call the function when the component mounts
      handleInitialChange();
    }
    const keyValuePairs = custData.paymentMode.split(",");
    const paymentMethodsArray = [];

    keyValuePairs.forEach((pair) => {
      const [method, amount] = pair.split(":");
      paymentMethodsArray.push({
        mode: method.trim(),
        amount: parseInt(amount.trim(), 10),
      });
    });
    setPayments(paymentMethodsArray);
    setDiscountAmount(custData.offer);

    calculateOldPrices();
    // setGrandTotal(custData.price - custData.receivedAmt);
  }, [updateOldPrices]);

  const calculateOldPrices = () => {
    const custData = JSON.parse(csData);
    setGrandTotal(custData.price - custData.receivedAmt);
    setPaymentAmount(custData.price - custData.receivedAmt);
    setPaidAmount(custData.receivedAmt);
    window.scrollTo(0, 0);
  };
  // console.log(payments);
  let sameOldItems = [];
  let differentNewItems = [];
  let removedItems = [];
  const checkBothArray = () => {
    sameOldItems = allSelectedProducts.filter(
      (item) => item.orderId !== undefined || null
    );

    differentNewItems = allSelectedProducts.filter(
      (item) => item.orderId === undefined
    );

    removedItems = allOldSelectedProducts.filter((oldItem) => {
      return !allSelectedProducts.some(
        (newItem) =>
          Object.keys(oldItem).length === Object.keys(newItem).length &&
          Object.keys(oldItem).every((key) => oldItem[key] === newItem[key])
      );
    });

    console.log(sameOldItems, "sameOldItems");
    console.log(differentNewItems, "differentNewItems");
    console.log(removedItems, "removedItems");
    if (differentNewItems.length === 0 && removedItems.length === 0) {
      alert("Same Products as Before");
    } else {
      updateOrderItems();
    }
    return {
      same: sameOldItems,
      different: differentNewItems,
      removed: removedItems,
    };
  };

  const updateOrderItems = () => {
    if (removedItems.length > 0) {
      console.log("need to remove");
      console.log(removedItems, "removedItems");
    }
    if (differentNewItems.length > 0) {
      console.log("need to add new");
    }
  };
  // const comparisonResult = checkBothArray();
  // console.log("Same Objects:", comparisonResult.same);
  // console.log("Different Objects:", comparisonResult.different);

  return (
    <div>
      <AdminHeading />

      <div className="adminMainBodyBox">
        {/* <AdminBreadCrump
            title={"New Invoice"}
            companyName={"Loyalstring"}
            module={"Trading"}
            page={"Invoice"}
          /> */}
        <div className="adminAddCategoryMainBox">
          <div
            style={{ marginBottom: "50px", paddingTop: "0px" }}
            className="adminAddCategoryInnerBox"
          >
            {/* <h4 className="adminInvoiceAddTitles">Add Customer</h4> */}
            <div
              id="adminInvoiceAddCustomerTitle"
              className="adminInvoiceSelectLabelBox"
            >
              <div className="adminInvoiceSelectItem">
                <label>Customer Name</label>
                <input
                  style={{ width: "20vw" }}
                  type="text"
                  name="customerName"
                  value={customerName}
                  onInput={handleNameInputChange}
                  list="customerNamesList"
                />
                <datalist id="customerNamesList">
                  {allCsData.map((customer) => (
                    <option
                      key={customer.id}
                      value={`${customer.firstName} ${customer.lastName}`}
                    />
                  ))}
                </datalist>
                <button
                  onClick={() => {
                    //   setSelectedCustomer(null),
                    // setAddNewCustomer(!addNewCustomer),
                    checkIfNewCs();
                  }}
                  className="adminInvoiceAddCustomerOption"
                >
                  <AiOutlinePlusSquare size={"20px"} />
                </button>
              </div>
              <div className="adminInvoiceSelectItem">
                <label>Customer Mobile</label>
                <input
                  type="text"
                  name="customerMobile"
                  value={customerMobile}
                  onInput={handleMobileInputChange}
                  list="customerMobilesList"
                />
                <datalist id="customerMobilesList">
                  {allCsData.map((customer) => (
                    <option key={customer.id} value={customer.mobile} />
                  ))}
                </datalist>
              </div>
              <div className="adminInvoiceSelectItem">
                <label>Customer Email</label>
                <input
                  type="text"
                  name="customerEmails"
                  value={customerEmail}
                  onInput={handleEmailInputChange}
                  list="customerEmailsList"
                />
                <datalist id="customerEmailsList">
                  {allCsData.map((customer) => (
                    <option key={customer.id} value={customer.email} />
                  ))}
                </datalist>
              </div>
            </div>
            {selectedCustomer && !selectedCustomerEdit ? (
              <div className="adminInvoiceAddedCustomerBox">
                <div>
                  <p>
                    {selectedCustomer.firstName} {selectedCustomer.lastName}
                  </p>
                  <p>{selectedCustomer.mobile}</p>
                  <p>{selectedCustomer.email}</p>
                  <p>
                    Curr Add: {selectedCustomer.currAddStreet}{" "}
                    {selectedCustomer.currAddTown}{" "}
                    {selectedCustomer.currAddState}{" "}
                    {selectedCustomer.currAddPinCode}
                  </p>
                  <p>
                    Per Add: {selectedCustomer.perAddStreet}{" "}
                    {selectedCustomer.perAddTown} {selectedCustomer.perAddState}{" "}
                    {selectedCustomer.perAddPinCode}
                  </p>
                  <p>GSTIN No.: {selectedCustomer.gstNo}</p>
                </div>
                <div className="adminInvoiceAddedCustomerEditIconBox">
                  <button
                    onClick={() => {
                      setSelectedCustomerEdit(!selectedCustomerEdit),
                        scrollToCenter("adminInvoiceAddedCustomerEdit");
                    }}
                  >
                    <AiOutlineEdit size={"20px"} />
                  </button>
                  <button
                    onClick={() => {
                      setSelectedCustomer(null);
                      scrollToCenter("adminInvoiceAddCustomerTitle");
                    }}
                    id="adminInvoiceAddedCustomerRemoveIcon"
                  >
                    <RiDeleteBin2Line size={"20px"} />
                  </button>
                </div>
              </div>
            ) : selectedCustomer && selectedCustomerEdit ? (
              <div className="adminInvoiceAddedCustomerEditMainBox">
                <p>Personal Details</p>
                <div className="adminInvoiceAddedCustomerEditBox">
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>First Name</label>
                    <input
                      onChange={(e) =>
                        handleCustomerInputChange(e, "firstName")
                      }
                      value={selectedCustomer.firstName}
                      type="text"
                    />
                  </div>
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>Last Name</label>
                    <input
                      onChange={(e) => handleCustomerInputChange(e, "lastName")}
                      value={selectedCustomer.lastName}
                      type="text"
                    />
                  </div>
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>Mobile</label>
                    <input
                      onChange={(e) => handleCustomerInputChange(e, "mobile")}
                      value={selectedCustomer.mobile}
                      type="text"
                    />
                  </div>
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>Email</label>
                    <input
                      onChange={(e) => handleCustomerInputChange(e, "email")}
                      value={selectedCustomer.email}
                      type="text"
                    />
                  </div>
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>Aadhar No.</label>
                    <input
                      onChange={(e) => handleCustomerInputChange(e, "aadharNo")}
                      value={selectedCustomer.aadharNo}
                      type="text"
                    />
                  </div>
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>Pan No.</label>
                    <input
                      onChange={(e) => handleCustomerInputChange(e, "panNo")}
                      value={selectedCustomer.panNo}
                      type="text"
                    />
                  </div>
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>GSTIN No.</label>
                    <input
                      onChange={(e) => handleCustomerInputChange(e, "gstNo")}
                      value={selectedCustomer.gstNo}
                      type="text"
                    />
                  </div>
                </div>
                <p>Billing Add</p>
                <div className="adminInvoiceAddedCustomerEditBox">
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>Street</label>
                    <input
                      onChange={(e) =>
                        handleCustomerInputChange(e, "currAddStreet")
                      }
                      value={selectedCustomer.currAddStreet}
                      type="text"
                    />
                  </div>
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>Town</label>
                    <input
                      onChange={(e) =>
                        handleCustomerInputChange(e, "currAddTown")
                      }
                      value={selectedCustomer.currAddTown}
                      type="text"
                    />
                  </div>
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>State</label>
                    <input
                      onChange={(e) =>
                        handleCustomerInputChange(e, "currAddState")
                      }
                      value={selectedCustomer.currAddState}
                      type="text"
                    />
                  </div>
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>Pincode</label>
                    <input
                      onChange={(e) =>
                        handleCustomerInputChange(e, "currAddPinCode")
                      }
                      value={selectedCustomer.currAddPinCode}
                      type="text"
                    />
                  </div>
                </div>
                <p>Per Add</p>
                <div className="adminInvoiceAddedCustomerEditBox">
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>Street</label>
                    <input
                      onChange={(e) =>
                        handleCustomerInputChange(e, "perAddStreet")
                      }
                      value={selectedCustomer.perAddStreet}
                      type="text"
                    />
                  </div>
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>Town</label>
                    <input
                      onChange={(e) =>
                        handleCustomerInputChange(e, "perAddTown")
                      }
                      value={selectedCustomer.perAddTown}
                      type="text"
                    />
                  </div>
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>State</label>
                    <input
                      onChange={(e) =>
                        handleCustomerInputChange(e, "perAddState")
                      }
                      value={selectedCustomer.perAddState}
                      type="text"
                    />
                  </div>
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>Pincode</label>
                    <input
                      onChange={(e) =>
                        handleCustomerInputChange(e, "perAddPinCode")
                      }
                      value={selectedCustomer.perAddPinCode}
                      type="text"
                    />
                  </div>
                </div>
                <div className="adminInvoiceAddedCustomerEditButtonBox">
                  <button onClick={() => updateCustomerDetails()}>Save</button>
                  <button
                    onClick={() => {
                      scrollToCenter("adminInvoiceAddCustomerTitle"),
                        setSelectedCustomerEdit(!selectedCustomerEdit);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : null}

            <h4
              id="adminInvoiceAddedCustomerEdit"
              className="adminInvoiceAddTitles"
            >
              Add Product
            </h4>
            <div className="adminInvoiceAddProductsOptionsTypeBox">
              <div className="adminAddCategoryInnerBoxTitlesBox">
                <button
                  onClick={() => {
                    setActive("Sell");
                  }}
                  style={{ height: "40px" }}
                  className={
                    active === "Sell"
                      ? "adminAddCategoryInnerBoxTitle"
                      : "adminAddCategoryInnerBoxTitle activeCategoryTitle"
                  }
                >
                  <div
                    className={
                      active === "Sell"
                        ? "adminAddCategoryInnerBoxTitleLogo"
                        : "adminAddCategoryInnerBoxTitleLogo activeCategoryLogo"
                    }
                    style={{
                      height: "20px",
                      width: "20px",
                      padding: "3px",
                      marginInline: "3px",
                    }}
                  >
                    {/* 01 */}
                    <AiOutlineSend size={"15px"} />
                  </div>
                  <p style={{ fontSize: "12px" }}>Sell</p>
                </button>

                <button
                  onClick={() => setActive("Purchase")}
                  style={{ height: "40px" }}
                  className={
                    active === "Purchase"
                      ? "adminAddCategoryInnerBoxTitle"
                      : "adminAddCategoryInnerBoxTitle activeCategoryTitle"
                  }
                >
                  <div
                    style={{
                      height: "20px",
                      width: "20px",
                      padding: "3px",
                      marginInline: "3px",
                    }}
                    className={
                      active === "Purchase"
                        ? "adminAddCategoryInnerBoxTitleLogo"
                        : "adminAddCategoryInnerBoxTitleLogo activeCategoryLogo"
                    }
                  >
                    {/* 02 */}

                    <LiaCartPlusSolid size={"30px"} />
                  </div>
                  <p style={{ fontSize: "12px" }}>Purchase</p>
                </button>
                <button
                  onClick={() => setActive("Unlabel")}
                  style={{ height: "40px" }}
                  className={
                    active === "Unlabel"
                      ? "adminAddCategoryInnerBoxTitle"
                      : "adminAddCategoryInnerBoxTitle activeCategoryTitle"
                  }
                >
                  <div
                    style={{
                      height: "20px",
                      width: "20px",
                      padding: "3px",
                      marginInline: "3px",
                    }}
                    className={
                      active === "Unlabel"
                        ? "adminAddCategoryInnerBoxTitleLogo"
                        : "adminAddCategoryInnerBoxTitleLogo activeCategoryLogo"
                    }
                  >
                    {/* 02 */}

                    <MdOutlineLabelOff size={"17px"} />
                  </div>
                  <p style={{ fontSize: "12px" }}>Unlabel</p>
                </button>
              </div>
            </div>
            {active === "Sell" ? (
              <div className="adminInvoiceAddProductsOptionsMainSellBox">
                {!productsLoading ? (
                  <div className="adminInvoiceAddProductsOptionsMainBox">
                    <div className="adminInvoiceAddProductsOptionsInnerBox">
                      <table>
                        <thead>
                          <tr>
                            <th>ITEM DETAILS</th>
                            <th>RATE</th>
                            <th>GROSS WT</th>
                            <th>NET WT</th>
                            <th>PURITY</th>
                            <th>MAKING</th>
                            <th>PRICE</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allSelectedProducts.length > 0
                            ? allSelectedProducts.map((x, index) => (
                                <tr
                                  style={{
                                    borderBottom:
                                      "1px solid  rgba(128, 128, 128, 0.3)",
                                  }}
                                >
                                  <td>
                                    <div className="adminAddInvoiceMainAddLabelOption">
                                      <div className="adminAddInvoiceMainAddLabelOptionImageBox">
                                        <BsCardImage size={"30px"} />
                                      </div>
                                      <div className="adminAddInvoiceMainAddLabelOptionLabelBox">
                                        {x.purchase ? (
                                          <p
                                            style={{
                                              textAlign: "left",
                                              margin: "5px",
                                              padding: "5px",
                                              marginBottom: "0px",
                                              paddingBottom: "0px",
                                              color: "red",
                                            }}
                                          >
                                            Purchase
                                          </p>
                                        ) : x.unlabel ? (
                                          <p
                                            style={{
                                              textAlign: "left",
                                              margin: "5px",
                                              padding: "5px",
                                              marginBottom: "0px",
                                              paddingBottom: "0px",
                                              color: "green",
                                            }}
                                          >
                                            Unlabel
                                          </p>
                                        ) : (
                                          <p
                                            style={{
                                              textAlign: "left",
                                              margin: "5px",
                                              padding: "5px",
                                              marginBottom: "0px",
                                              paddingBottom: "0px",
                                            }}
                                          >
                                            {x.itemCode}
                                          </p>
                                        )}
                                        {x.purchase ? (
                                          <p
                                            style={{
                                              fontWeight: "bold",
                                              color: "red",
                                              fontSize: "10px",
                                              textAlign: "left",
                                              margin: "0px 5px",
                                              padding: "0px 5px",
                                            }}
                                          >
                                            {`${x.CategoryName}, ${x.productname}`}
                                          </p>
                                        ) : x.unlabel ? (
                                          <p
                                            style={{
                                              fontWeight: "bold",
                                              color: "green",
                                              fontSize: "10px",
                                              textAlign: "left",
                                              margin: "0px 5px",
                                              padding: "0px 5px",
                                            }}
                                          >
                                            {`${x.CategoryName}, ${x.product_Name}, ${x.collectionName}`}
                                          </p>
                                        ) : (
                                          <p
                                            style={{
                                              fontWeight: "bold",
                                              color: "#02a8b5",
                                              fontSize: "10px",
                                              textAlign: "left",
                                              margin: "0px 5px",
                                              padding: "0px 5px",
                                            }}
                                          >
                                            {`${x.category_Name}, ${x.itemType}, ${x.collection}`}
                                          </p>
                                        )}
                                        <p
                                          style={{
                                            fontWeight: "bold",
                                            color: "#02a8b5",
                                            fontSize: "10px",
                                            textAlign: "left",
                                            margin: "0px 5px",
                                            padding: "0px 5px",
                                          }}
                                        ></p>
                                      </div>
                                      <div className="adminAddInvoiceMainAddLabelOptionEditIconBox">
                                        <button
                                          onClick={() => {
                                            editItem(x);
                                          }}
                                          className="adminAddInvoiceMainAddLabelOptionEditIcon"
                                        >
                                          <AiOutlineEdit />
                                        </button>
                                        <button
                                          style={{ marginBottom: "5px" }}
                                          onClick={() => {
                                            x.purchase
                                              ? removePurchaseProductFromList(
                                                  index
                                                )
                                              : x.unlabel
                                              ? removePurchaseProductFromList(
                                                  index
                                                )
                                              : removePurchaseProductFromList(
                                                  index
                                                );
                                            // removeProductFromList(x.id);
                                          }}
                                          className="adminAddInvoiceMainAddLabelOptionDeleteIcon"
                                        >
                                          <RxCross2 />
                                        </button>
                                      </div>
                                    </div>
                                  </td>
                                  {x.purchase ? (
                                    <td>
                                      {" "}
                                      ₹{parseFloat(x.GoldRate).toFixed(0)}
                                    </td>
                                  ) : x.unlabel ? (
                                    <td>
                                      {" "}
                                      ₹{parseFloat(x.GoldRate).toFixed(0)}
                                    </td>
                                  ) : (
                                    <td>
                                      {" "}
                                      ₹
                                      {parseFloat(
                                        x.tblPurity.todaysRate
                                      ).toFixed(0)}
                                    </td>
                                  )}{" "}
                                  <td>{parseFloat(x.grosswt).toFixed(3)}</td>
                                  {x.purchase ? (
                                    <td> {parseFloat(x.netwt).toFixed(3)}</td>
                                  ) : x.unlabel ? (
                                    <td> {parseFloat(x.netwt).toFixed(3)}</td>
                                  ) : (
                                    <td> {parseFloat(x.netWt).toFixed(3)}</td>
                                  )}
                                  <td> {parseFloat(x.purity).toFixed(3)}</td>
                                  <td> ₹{parseFloat(x.making).toFixed(3)}</td>
                                  {x.purchase ? (
                                    <td>
                                      ₹{parseFloat(x.PurchaseAmount).toFixed(3)}
                                    </td>
                                  ) : (
                                    <td>
                                      ₹
                                      {parseFloat(
                                        parseFloat(x.finalPrice) +
                                          parseFloat(x.totalGstAmount)
                                      ).toFixed(3)}
                                    </td>
                                  )}
                                </tr>
                              ))
                            : null}
                          <tr>
                            <td>
                              <div className="adminAddInvoiceMainAddLabelOption">
                                <div className="adminAddInvoiceMainAddLabelOptionImageBox">
                                  <BsCardImage size={"30px"} />
                                </div>
                                <div className="adminAddInvoiceMainAddLabelOptionLabelBox">
                                  <input
                                    type="text"
                                    placeholder="Type or click to select an item"
                                    name="productLabel"
                                    value={labelName}
                                    onInput={handleProductLabelChange}
                                    onKeyPress={(e) => {
                                      if (e.key === "Enter") {
                                        // Call your function here
                                        if (selectedProduct.length !== 0) {
                                          calculateFinalPrice(
                                            selectedProduct,
                                            true
                                          );
                                        } else {
                                          null;
                                        }
                                      }
                                    }}
                                    list="productLabelList"
                                  />
                                  <datalist id="productLabelList">
                                    {allProducts.map((product) => (
                                      <option
                                        key={product.id}
                                        value={product.itemCode}
                                      />
                                    ))}
                                  </datalist>
                                </div>
                              </div>
                            </td>
                            {selectedProduct.length > 0 ? (
                              <td>{selectedProduct.tblPurity.todaysRate}</td>
                            ) : (
                              <td>0</td>
                            )}
                            <td>{selectedProduct.grosswt}</td>
                            <td> {selectedProduct.netWt}</td>
                            <td> {selectedProduct.purity}</td>
                            <td>{selectedProduct.making}</td>

                            <td>₹{Math.ceil(totalPayableAmount)} </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <tr>
                    <td>
                      <div className="adminAddInvoiceMainAddLabelOption">
                        <div className="adminAddInvoiceMainAddLabelOptionImageBox">
                          <BsCardImage size={"30px"} />
                        </div>
                        <div className="adminAddInvoiceMainAddLabelOptionLabelBox">
                          <input
                            type="text"
                            placeholder="Type or click to select an item"
                            name="productLabel"
                            value={labelName}
                            onInput={handleProductLabelChange}
                            list="productLabelList"
                          />
                          <datalist id="productLabelList">
                            {allProducts.map((product) => (
                              <option
                                key={product.id}
                                value={product.itemCode}
                              />
                            ))}
                          </datalist>
                        </div>
                      </div>
                    </td>
                    <td>{selectedProduct.grosswt}</td>
                    <td> {selectedProduct.netWt}</td>
                    <td> {selectedProduct.purity}</td>
                    <td> {selectedProduct.making}</td>

                    <td>
                      {parseFloat(
                        parseFloat(selectedProduct.finalPrice) +
                          parseFloat(selectedProduct.totalGstAmount)
                      ).toFixed(3)}
                    </td>
                  </tr>
                )}

                {openEditBox ? (
                  <div className="adminInvoiceOpenEditMainBox">
                    <div className="adminInvoiceOpenEditInnerBox">
                      <div className="adminInvoiceOpenEditInnerTitleBox">
                        <p>Edit Item</p>
                        <button
                          onClick={closeEditItem}
                          className="adminAddInvoiceMainAddLabelOptionDeleteIcon"
                        >
                          <RxCross2 size={"25px"} />
                        </button>
                      </div>
                      <div className="adminInvoiceOpenEditOuterGridBox">
                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>Gross Wt</label>
                          <input
                            type="text"
                            placeholder={openEditProduct.grosswt}
                            value={openEditProduct.grosswt}
                            onChange={(e) => handleInputChange2(e, "grosswt")}
                          />
                        </div>

                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>Stone Wt</label>{" "}
                          <input
                            type="number"
                            placeholder={openEditProduct.stoneWeight}
                            value={openEditProduct.stoneWeight}
                            onChange={(e) =>
                              handleInputChange2(e, "stoneWeight")
                            }
                          />
                        </div>
                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>Net Wt</label>
                          <input
                            type="text"
                            placeholder={openEditProduct.netWt}
                            value={openEditProduct.netWt}
                            onChange={(e) => handleInputChange2(e, "netWt")}
                          />
                        </div>
                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>Stone Amount</label>{" "}
                          <input
                            type="number"
                            placeholder={openEditProduct.stoneAmount}
                            value={openEditProduct.stoneAmount}
                            onChange={(e) =>
                              handleInputChange2(e, "stoneAmount")
                            }
                          />
                        </div>
                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>Product Name</label>
                          <input
                            type="text"
                            placeholder={openEditProduct.product_Name}
                            value={openEditProduct.product_Name}
                            onChange={(e) =>
                              handleInputChange2(e, "product_Name")
                            }
                          />
                        </div>
                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>HUID Code</label>
                          <input
                            type="text"
                            maxLength={6}
                            placeholder={openEditProduct.huidCode}
                            value={openEditProduct.huidCode}
                            onChange={(e) => handleInputChange2(e, "huidCode")}
                          />
                        </div>

                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>Making PerGram</label>{" "}
                          <input
                            type="number"
                            placeholder={openEditProduct.making_per_gram}
                            value={openEditProduct.making_per_gram}
                            onChange={(e) =>
                              handleInputChange2(e, "making_per_gram")
                            }
                          />
                        </div>

                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>Making Percentage</label>{" "}
                          <input
                            type="number"
                            placeholder={openEditProduct.making_Percentage}
                            value={openEditProduct.making_Percentage}
                            onChange={(e) =>
                              handleInputChange2(e, "making_Percentage")
                            }
                          />
                        </div>

                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>Making Fixed Amount</label>{" "}
                          <input
                            type="number"
                            placeholder={openEditProduct.making_Fixed_Amt}
                            value={openEditProduct.making_Fixed_Amt}
                            onChange={(e) =>
                              handleInputChange2(e, "making_Fixed_Amt")
                            }
                          />
                        </div>
                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>Making Fixed Wastage</label>
                          <input
                            type="number"
                            placeholder={openEditProduct.making_Fixed_Wastage}
                            value={openEditProduct.making_Fixed_Wastage}
                            onChange={(e) =>
                              handleInputChange2(e, "making_Fixed_Wastage")
                            }
                          />
                        </div>
                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>Pieces</label>{" "}
                          <input
                            type="number"
                            placeholder={openEditProduct.pieces}
                            value={openEditProduct.pieces}
                            onChange={(e) => handleInputChange2(e, "pieces")}
                          />
                        </div>
                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>Size</label>{" "}
                          <input
                            type="number"
                            placeholder={openEditProduct.size}
                            value={openEditProduct.size}
                            onChange={(e) => handleInputChange2(e, "size")}
                          />
                        </div>

                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>MRP</label>{" "}
                          <input
                            type="number"
                            placeholder={openEditProduct.mrp}
                            value={openEditProduct.mrp}
                            onChange={(e) => handleInputChange2(e, "mrp")}
                          />
                        </div>
                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>Description</label>{" "}
                          <input
                            type="text"
                            placeholder={openEditProduct.description}
                            value={openEditProduct.description}
                            onChange={(e) =>
                              handleInputChange2(e, "description")
                            }
                          />
                        </div>
                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>Occasion</label>{" "}
                          <input
                            type="text"
                            placeholder={openEditProduct.occasion}
                            value={openEditProduct.occasion}
                            onChange={(e) => handleInputChange2(e, "occasion")}
                          />
                        </div>
                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>Gender</label>{" "}
                          <input
                            type="text"
                            placeholder={openEditProduct.gender}
                            value={openEditProduct.gender}
                            onChange={(e) => handleInputChange2(e, "gender")}
                          />
                        </div>

                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>Featured</label>{" "}
                          <input
                            type="text"
                            placeholder={openEditProduct.featured}
                            value={openEditProduct.featured}
                            onChange={(e) => handleInputChange2(e, "featured")}
                          />
                        </div>
                        <div className="adminInvoiceOpenEditInnerGridItem">
                          {/* <label>Update</label>{" "} */}.{" "}
                          <button
                            onClick={() => {
                              setOpenEditBox(false),
                                setSelectedProduct([]),
                                setLabelName("");
                            }}
                            className="adminInvoiceEditProductSaveButton"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : active === "Purchase" ? (
              <div className="adminInvoiceAddProductsOptionsMainPurchaseBox">
                <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                  <label>Category</label>
                  <select
                    name="category_id"
                    onChange={handleInputChangePurchase}
                    value={purchaseProduct.names}
                  >
                    <option value={""}>Select an Category</option>
                    {allCategories.map((x) => {
                      return (
                        <option value={`${x.id},${x.name}`}>{x.name}</option>
                      );
                    })}
                  </select>
                </div>
                <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                  <label>Product</label>

                  <select
                    name="productname"
                    onChange={handleInputChangePurchase}
                    value={purchaseProduct.productname}
                  >
                    <option value={""}>Select an Product</option>
                    {filteredProducts.map((x) => {
                      return (
                        <option value={x.productTitle}>{x.productTitle}</option>
                      );
                    })}
                  </select>
                </div>
                <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                  <label>Purity</label>

                  <select
                    name="purityRate"
                    onChange={handleInputChangePurchase}
                    value={purchaseProduct.purities}
                  >
                    <option>Select an Purity</option>
                    {filteredPurities.map((x) => {
                      return (
                        <option value={`${x.purity},${x.todaysRate}`}>
                          {x.purity}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                  <label>Gross Wt</label>
                  <input
                    name="grosswt"
                    onChange={handleInputChangePurchase}
                    type="text"
                    value={purchaseProduct.grosswt}
                  />
                </div>
                <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                  <label>Net Wt</label>
                  <input
                    name="netwt"
                    onChange={handleInputChangePurchase}
                    type="text"
                    value={purchaseProduct.netwt}
                  />
                </div>
                {/* <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                    <label>Stone Wt</label>
                    <input
                      name="stonewt"
                      onChange={handleInputChangePurchase}
                      type="text"
                      value={purchaseProduct.stonewt}
                    />
                  </div> */}
                <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                  <label>Gold Rate</label>
                  <input
                    name="GoldRate"
                    onChange={handleInputChangePurchase}
                    type="text"
                    value={purchaseProduct.GoldRate}
                  />
                </div>
                <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                  <label>Fine Percentage</label>
                  <input
                    name="Finepercent"
                    onChange={handleInputChangePurchase}
                    type="text"
                    value={purchaseProduct.Finepercent}
                  />
                </div>
                <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                  <label>Quantity</label>
                  <input
                    name="Quantity"
                    onChange={handleInputChangePurchase}
                    type="text"
                    value={purchaseProduct.Quantity}
                  />
                </div>

                <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                  <label>Purchase Amount</label>
                  <input
                    name="PurchaseAmount"
                    onChange={handleInputChangePurchase}
                    type="text"
                    value={purchaseProduct.PurchaseAmount}
                  />
                </div>
                <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                  {/* <label>Add</label>  */}
                  <button
                    onClick={() => addPurchaseProductToList(purchaseProduct)}
                  >
                    Add
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <div className="adminInvoiceAddProductsOptionsMainPurchaseBox">
                  <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                    <label>Category</label>
                    <select
                      name="category_id"
                      onChange={handleInputChangeUnlabel}
                      value={unlabelProduct.names}
                    >
                      <option value={""}>Select an Category</option>
                      {allCategories.map((x) => {
                        return (
                          <option value={`${x.id},${x.name}`}>{x.name}</option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                    <label>Product</label>

                    <select
                      name="productTypeId"
                      onChange={handleInputChangeUnlabel}
                      value={unlabelProduct.productTypeNames}
                    >
                      <option value={""}>Select an Product</option>
                      {filteredProductsUnlabel.map((x) => {
                        return (
                          <option value={`${x.id},${x.productTitle}`}>
                            {x.productTitle}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                    <label>Collection</label>

                    <select
                      name="collectionName"
                      onChange={handleInputChangeUnlabel}
                      value={unlabelProduct.collectionNames}
                    >
                      <option value={""}>Select an Product</option>
                      {filteredUnlabelCollection.map((x) => {
                        return (
                          <option
                            value={`${x.id},${x.collection},${x.collectionId}`}
                          >
                            {x.collection}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                    <label>Purity</label>

                    <select
                      name="purityRate"
                      onChange={handleInputChangeUnlabel}
                      value={unlabelProduct.purities}
                    >
                      <option>Select an Purity</option>
                      {filteredPuritiesUnlabel.map((x) => {
                        return (
                          <option value={`${x.purity},${x.todaysRate}`}>
                            {x.purity}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                    <label>Gross Wt</label>
                    <input
                      name="grosswt"
                      onChange={handleInputChangeUnlabel}
                      // type="text"
                      value={unlabelProduct.grosswt}
                      type="number"
                      max={unlabelProduct.MaxGrossWt}
                    />
                  </div>
                  <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                    <label>Net Wt</label>
                    <input
                      name="netwt"
                      onChange={handleInputChangeUnlabel}
                      type="number"
                      max={unlabelProduct.MaxNetWt}
                      value={unlabelProduct.netwt}
                    />
                  </div>
                  {/* <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                    <label>Stone Wt</label>
                    <input
                      name="stonewt"
                      onChange={handleInputChangePurchase}
                      type="text"
                      value={purchaseProduct.stonewt}
                    />
                  </div> */}
                  <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                    <label>Gold Rate</label>
                    <input
                      name="GoldRate"
                      onChange={handleInputChangeUnlabel}
                      type="text"
                      value={unlabelProduct.GoldRate}
                    />
                  </div>
                  <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                    <label>Making Fixed Amt</label>
                    <input
                      name="making_Fixed_Amt"
                      onChange={handleInputChangeUnlabel}
                      type="text"
                      value={unlabelProduct.making_Fixed_Amt}
                    />
                  </div>
                  <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                    <label>Making Percentage</label>
                    <input
                      name="making_Percentage"
                      onChange={handleInputChangeUnlabel}
                      type="text"
                      value={unlabelProduct.making_Percentage}
                    />
                  </div>
                  <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                    <label>Making Pergram</label>
                    <input
                      name="making_per_gram"
                      onChange={handleInputChangeUnlabel}
                      type="text"
                      value={unlabelProduct.making_per_gram}
                    />
                  </div>
                  <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                    <label>Making Fixed Wastage</label>
                    <input
                      name="making_Fixed_Wastage"
                      onChange={handleInputChangeUnlabel}
                      type="text"
                      value={unlabelProduct.making_Fixed_Wastage}
                    />
                  </div>
                  <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                    <label>Quantity</label>
                    <input
                      name="Quantity"
                      onChange={handleInputChangeUnlabel}
                      type="number"
                      min={0}
                      max={parseInt(unlabelProduct.MaxQuantity)}
                      value={unlabelProduct.Quantity}
                    />
                  </div>

                  <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                    <label>Total Amount</label>
                    <input
                      name="unlabelAmount"
                      onChange={handleInputChangeUnlabel}
                      type="text"
                      value={unlabelProduct.unlabelAmount}
                    />
                  </div>
                  <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                    {/* <label>Add</label>  */}
                    <button
                      onClick={() => addPurchaseProductToList(unlabelProduct)}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div
              style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
              className="adminInviceAddedProductsTotalOuterBox"
            >
              {" "}
              <div className="adminInviceAddedProductsTotalItemBox">
                <label>Taxable Amount</label>
                <input
                  type="text"
                  value={parseInt(allProdctsNetAmount).toLocaleString("en-IN")}
                  readOnly
                />
                <label>R.O./Discount(-)</label>
                <input
                  type="text"
                  value={parseInt(discountAmount).toLocaleString("en-IN")}
                  readOnly
                />
                <label>GST 3%</label>
                <input
                  type="text"
                  value={parseInt(totalPayableGstAmount).toLocaleString(
                    "en-IN"
                  )}
                  readOnly
                />
                <label>Total Amount</label>
                <input
                  type="text"
                  style={{ backgroundColor: "wheat" }}
                  value={Math.ceil(totalPayableAmount)}
                  onChange={(e) => {
                    const newTotalPayableAmount = parseFloat(e.target.value);
                    if (!isNaN(newTotalPayableAmount)) {
                      // Check if the input value is a valid number
                      setTotalPayableGstAmount(
                        ((newTotalPayableAmount / 103) * 3).toFixed(2)
                      );
                      changeTotalPrice(e.target.value);

                      // setGrandTotal(0);
                      // setOldGoldAmount(0);
                    } else {
                      //   setTotalPayableAmount(allProdctsNetAmount);
                      setTotalPayableAmount(0);
                    }
                  }}
                />
                <label>Purchase Amount</label>
                <input
                  type="text"
                  readOnly
                  value={parseInt(oldGoldAmount)}
                  onChange={(e) => {
                    if (!isNaN(oldGoldAmount)) {
                      setOldGoldAmount(e.target.value),
                        // Check if the input value is a valid number
                        setGrandTotal(
                          parseFloat(
                            parseFloat(totalPayableAmount) -
                              parseFloat(e.target.value)
                          )
                        );
                      setPaymentAmount(
                        parseFloat(
                          parseFloat(totalPayableAmount) -
                            parseFloat(e.target.value)
                        )
                      );
                    } else {
                      // setTotalPayableAmount(allProdctsNetAmount);
                      setGrandTotal(0);
                      setOldGoldAmount(0);
                    }
                  }}
                />
                <label>Paid Amount</label>
                {/* <input type="text" value={parseInt(totalPaidAmount)} readOnly /> */}
                <input type="text" value={parseInt(paidAmount)} readOnly />
                <label>Grand Total</label>
                <input
                  type="text"
                  value={parseInt(grandTotal).toLocaleString("en-IN")}
                  readOnly
                />
              </div>
              <div className="adminInviceAddedProductsTotalAmountOuterBox">
                <div
                  style={{
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    textAlign: "left",
                  }}
                  className="adminInviceAddedProductsTotalItemBox"
                >
                  <label>Payment Mode</label>
                  <select
                    style={{ width: "auto" }}
                    onChange={(e) => setPaymentOptions(e.target.value)}
                    value={paymentOptions}
                  >
                    <option value={"Cash"}>Cash</option>
                    <option value={"Card"}>Card</option>
                    <option value={"UPI"}>UPI</option>
                    <option value={"Cheque"}>Cheque</option>
                    <option value={"RTGS"}>RTGS</option>
                    <option value={"MDS"}>MDS</option>
                    <option value={"Advance Amount"}>Advance Amount</option>
                  </select>
                  <label>Amount</label>
                  <div className="adminInviceAddedProductsAmountInputBox">
                    <input
                      type="number"
                      value={paymentAmount}
                      onChange={(e) => setPaymentAmount(e.target.value)}
                    />
                    <button onClick={addPayment}>
                      <GiCheckMark />
                    </button>
                    <button
                      onClick={() => {
                        setPaymentAmount(0), setPaymentOptions("Cash");
                      }}
                    >
                      <RxCross2 />
                    </button>
                  </div>
                </div>
                <div className="adminInviceAddedProductsTotalAmountBox">
                  <table>
                    <thead>
                      <tr>
                        <th>Mode</th>
                        <th>Amount</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payments.map((payment, index) => (
                        <tr key={index}>
                          <td>{payment.mode}</td>
                          <td>{payment.amount}</td>
                          {/* Button to delete the payment */}
                          <td onClick={() => deletePayment(index)}>
                            <button
                              className="adminInviceAddedProductsTotalAmountDeleteOption"
                              onClick={() => deletePayment(index)}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* <div
                id="adminInvoiceSelectLabelBox"
                className="adminInvoiceSelectLabelBox"
              >
                <div className="adminInvoiceSelectItem">
                  <label>Net Amount</label>
  
                  <input
                    type="text"
                    value={parseInt(allProdctsNetAmount)}
                    readOnly
                  />
                </div>
                <div className="adminInvoiceSelectItem">
                  <label>R.O./Discount(-)</label>
                  <input type="text" value={parseInt(discountAmount)} readOnly />
                </div>
                <div className="adminInvoiceSelectItem">
                  <label>GST 3%</label>
                  <input
                    type="text"
                    value={parseInt(totalPayableGstAmount)}
                    readOnly
                  />
                </div>
                <div className="adminInvoiceSelectItem">
                  <label>Total Amount</label>
                  <input
                    type="text"
                    style={{ backgroundColor: "wheat" }}
                    value={Math.ceil(totalPayableAmount)}
                    onChange={(e) => {
                      const newTotalPayableAmount = parseFloat(e.target.value);
                      if (!isNaN(newTotalPayableAmount)) {
                        // Check if the input value is a valid number
                        setTotalPayableGstAmount(
                          ((newTotalPayableAmount / 103) * 3).toFixed(2)
                        );
                        changeTotalPrice(e);
                      } else {
                        //   setTotalPayableAmount(allProdctsNetAmount);
                        setTotalPayableAmount(0);
                      }
                    }}
                  />
                  <button onClick={() => setUpdatePrices(!updatePrices)}>
                   Update
                    </button>
                </div>
                <div
                  style={{ marginBottom: "0px" }}
                  className="bulkProductAddingTableMain"
                >
                  <button
                    style={{ cursor: "pointer", paddingInline: "100px" }}
                    onClick={() => {
                      if (selectedCustomer && allSelectedProducts.length > 0) {
                        createOrder();
                      } else {
                        alert("Please add all details");
                      }
                    }}
                  >
                    Save
                  </button>
                </div>
              </div> */}
            <div className="adminInvoiceMainSaveButtonBox">
              <button
                style={{ marginInline: "10px" }}
                onClick={() => {
                  if (selectedCustomer && allSelectedProducts.length > 0) {
                    // createOrder();
                    // checkPurchaseItems();
                    checkBothArray();
                  } else {
                    alert("Please add all details");
                  }
                }}
              >
                Save
              </button>
              <button
                style={{ marginInline: "10px" }}
                onClick={() => resetAllFields()}
              >
                Reset{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
