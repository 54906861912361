import {
  ADMIN_LOGGED_IN,
  ADMIN_LOGGED_OUT,
  FETCH_ALL_PRODUCTS,
} from "../ActionTypes";

export const adminLoggedIn = (index) => ({
  type: ADMIN_LOGGED_IN,
  payload: index,
});
export const adminLoggedOut = (index) => ({
  type: ADMIN_LOGGED_OUT,
  payload: index,
});
export const fetchAllProducts = (data) => ({
  type: FETCH_ALL_PRODUCTS,
  payload: data,
});
