import React, { useEffect, useState } from "react";
import AdminHeading from "../Heading/AdminHeading";
import AdminBreadCrump from "../Heading/AdminBreadCrump";
import "../../PagesStyles/AdminMasters.css";
import { TbCircleNumber1 } from "react-icons/tb";
import { a18, a20, a21, a32, a33, a34, a36 } from "../../../Api/RootApiPath";
import { useSelector } from "react-redux";
import { RiListUnordered, RiPlayListAddLine } from "react-icons/ri";

export default function AdminAddCollection() {
  const [active, setActive] = useState("List");
  const [allCategories, setAllCategories] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [allCollections, setAllCollections] = useState([]);
  const [newCollection, setNewCollection] = useState({
    category_id: "",
    productType: "",
    collection_Name: "",
    slug: "",
    label: "",
  });
  const allStates = useSelector((state) => state);
  const adminLoggedIn = allStates.reducer1;
  let Entryby_Staff_id = parseInt(adminLoggedIn);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const fetchallCollections = async () => {
    try {
      const response = await fetch(a33);
      const data = await response.json();
      setAllCollections(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchAllCategories = async () => {
    try {
      const response = await fetch(a18);
      const data = await response.json();
      setAllCategories(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchAllProducts = async () => {
    try {
      const response = await fetch(a20);
      const data = await response.json();
      setAllProducts(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchallCollections();
  }, []);
  useEffect(() => {
    fetchAllCategories();
  }, []);
  useEffect(() => {
    fetchAllProducts();
  }, []);
  console.log(allCollections);
  const [editingId, setEditingId] = useState(null);
  const [editedData, setEditedData] = useState({});

  const handleEditClick = (id) => {
    setEditingId(id);
    // Find the item with the selected ID and set its data in the state
    const selectedItem = allCollections.find((x) => x.id === id);
    setEditedData(selectedItem);
  };

  const handleSaveClick = () => {
    handleSubmit();
    // Save the edited data to your state or send it to an API
    console.log("Edited Data:", editedData);
    setEditingId(null); // Exit editing mode
  };
  const handleSubmit = async () => {
    const formData = {
      ...editedData,
      id: editingId,
    };
    try {
      console.log(formData, "formData");
      const response = await fetch(a34, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();

      console.log(data, "updated");
      fetchallCollections();
    } catch (error) {
      console.error(error);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Update the edited data in the state
    setEditedData({ ...editedData, [name]: value });
  };

  const handleNewCollectionChange = (e) => {
    const { name, value } = e.target;
    // Update the edited data in the state
    setNewCollection({ ...newCollection, [name]: value });
  };
  const addNewCollection = async (e) => {
    e.preventDefault();
    const formData = {
      Category_id: newCollection.category_id,
      ProductType: newCollection.productType,
      Collection_Name: newCollection.collection_Name,
      Slug: newCollection.slug,
      Label: newCollection.label,
    };
    try {
      const response = await fetch(a32, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      fetchallCollections();
      setActive("List");
      setNewCollection({
        category: "",
        productTitle: "",
        slug: "",
        label: "",
        hsnCode: "",
        description: "",
      });
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };
  const filteredProducts = allProducts.filter(
    (product) => product.category_id == newCollection.category_id
  );
  const filteredProducts2 = allProducts.filter(
    (product) => product.category_id == editedData.category_id
  );
  return (
    <div>
      <AdminHeading />
      <div className="adminMainBodyBox">
        <AdminBreadCrump
          title={"Add Collection"}
          companyName={"Loyalstring"}
          module={"Masters"}
          page={"Collection"}
        />
        <div className="adminAddCategoryMainBox">
          <div className="adminAddCategoryInnerBox">
            <div className="adminAddCategoryInnerBoxTitlesBox">
              <div
                onClick={() => {
                  setActive("List");
                }}
                className={
                  active === "List"
                    ? "adminAddCategoryInnerBoxTitle"
                    : "adminAddCategoryInnerBoxTitle activeCategoryTitle"
                }
              >
                <div
                  className={
                    active === "List"
                      ? "adminAddCategoryInnerBoxTitleLogo"
                      : "adminAddCategoryInnerBoxTitleLogo activeCategoryLogo"
                  }
                >
                  <RiListUnordered />
                </div>
                <p>All Collections</p>
              </div>

              <div
                onClick={() => setActive("AddNew")}
                className={
                  active === "AddNew"
                    ? "adminAddCategoryInnerBoxTitle"
                    : "adminAddCategoryInnerBoxTitle activeCategoryTitle"
                }
              >
                <div
                  className={
                    active === "AddNew"
                      ? "adminAddCategoryInnerBoxTitleLogo"
                      : "adminAddCategoryInnerBoxTitleLogo activeCategoryLogo"
                  }
                >
                  <RiPlayListAddLine />
                </div>
                <p>Add Collection</p>
              </div>
            </div>
            <div
              className={
                active === "List" ? "adminCategoryListMainBox" : "none"
              }
            >
              <table>
                <thead>
                  <tr>
                    <th>Edit</th>
                    <th>ID</th>
                    <th>Category ID</th>
                    <th>Product Type</th>
                    <th>Collection Name</th>
                    <th>Label</th>
                    <th>Slug</th>
                  </tr>
                </thead>
                <tbody>
                  {allCollections.map((x) => (
                    <tr key={x.id}>
                      <td>
                        {editingId === x.id ? (
                          <button
                            className="adminAddCategorySaveButton"
                            onClick={handleSaveClick}
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            className="adminAddCategoryEditButton"
                            onClick={() => handleEditClick(x.id)}
                          >
                            Edit
                          </button>
                        )}
                      </td>
                      <td>{x.id}</td>
                      <td>
                        {editingId === x.id ? (
                          <select
                            name="category_id"
                            required="required"
                            value={editedData.category_id}
                            onChange={handleInputChange}
                          >
                            <option value="">Select an option</option>
                            {allCategories.map((x) => {
                              return (
                                <option value={parseInt(x.id)}>{x.name}</option>
                              );
                            })}
                          </select>
                        ) : (
                          x.category_id
                        )}
                      </td>

                      <td>
                        {editingId === x.id ? (
                          <select
                            id="product"
                            name="productType"
                            required="required"
                            value={editedData.productType || ""}
                            onChange={handleInputChange}
                          >
                            <option value="">Select an option</option>
                            {filteredProducts2.map((product) => (
                              <option
                                key={product.productTitle}
                                value={product.productTitle}
                              >
                                {product.productTitle}
                              </option>
                            ))}
                          </select>
                        ) : (
                          x.productType
                        )}
                      </td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="collection_Name"
                            value={editedData.collection_Name || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.collection_Name
                        )}
                      </td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="label"
                            value={(editedData.label || "").toUpperCase()} // Convert to uppercase
                            onChange={handleInputChange}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^A-Z0-9 ]/g,
                                ""
                              ); // Allow only uppercase letters, numbers, and spaces
                              handleInputChange(e);
                            }}
                          />
                        ) : (
                          x.label
                        )}
                      </td>

                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="slug"
                            value={editedData.slug || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.slug
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div
              className={
                active !== "List" ? "adminCategoryAddCategoryMainBox" : "none"
              }
            >
              <p>Add New Collection</p>
              <form onSubmit={addNewCollection}>
                <div className="adminCategoryAddCategoryInnerBox">
                  <label>Category</label>
                  <select
                    required="required"
                    name="category_id"
                    value={newCollection.category_id}
                    onChange={handleNewCollectionChange}
                  >
                    <option value="">Select an option</option>
                    {allCategories.map((x) => {
                      return <option value={parseInt(x.id)}>{x.name}</option>;
                    })}
                  </select>
                  <label>Product</label>
                  <select
                    id="product"
                    name="productType"
                    required="required"
                    value={newCollection.productType || ""}
                    onChange={handleNewCollectionChange}
                  >
                    <option value="">Select an option</option>
                    {filteredProducts.map((product) => (
                      <option
                        key={product.productTitle}
                        value={product.productTitle}
                      >
                        {product.productTitle}
                      </option>
                    ))}
                  </select>
                  <label>Collection Name</label>
                  <input
                    name="collection_Name"
                    value={newCollection.collection_Name}
                    onChange={handleNewCollectionChange}
                    type="text"
                  />

                  <label>Slug</label>
                  <input
                    name="slug"
                    value={newCollection.slug}
                    onChange={handleNewCollectionChange}
                    type="text"
                  />
                  <label>Label</label>
                  <input
                    name="label"
                    value={newCollection.label}
                    onChange={handleNewCollectionChange}
                    type="text"
                    required="required"
                    placeholder="Only Capitals"
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(
                        /[^A-Z0-9 ]/g,
                        ""
                      );
                    }}
                  />

                  <button type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
